import * as yup from "yup";
import React from "react";
import { Trans } from "react-i18next";

import { inputData } from "./regexAndRequiredInputsByCountry";

export const allowedCharFullNameRegex = /^[a-zA-Z-.' #,]+$/;
export const alphanumericWithSpacesRegex = /^[A-Za-z0-9 ]+$/;
export const fullNameRegex =
	/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,128}$/;
export const orderReceiptNoRegEx = /^([a-zA-Z0-9]){8}(-L\d{3})?(-B\d{3})?$/;
export const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9\s]).{7,32}$/;
export const usZipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
export const phoneNumberRegex = /^[+]?[()\- \d]{4,20}$/;

// Credit card regex
export const amexpRegEx = /^(?:3[47][0-9]{13})$/;
export const ccExpDate = /^1[0-2]\/\d\d|0[0-9]\/\d\d/;
export const ccLast4RegEx = /^\d{4}$/;
export const CVVregex = /^\d+\d+\d$/;
export const discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
export const mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
export const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;

export function requiredEmail() {
	return yup
		.string()
		.email(<Trans i18nKey="Common.enterValidEmail">Please enter a valid email address.</Trans>)
		.required(<Trans i18nKey="EditInfoModal.emailRequired">Email Address is required.</Trans>);
}

export function name() {
	const message = <Trans i18nKey="Common.enterValidName">Please enter a valid name</Trans>;
	return yup.string().matches(allowedCharFullNameRegex, { message, excludeEmptyString: true });
}

export function requiredFullName() {
	const message = (
		<Trans i18nKey="helperText.firstLastNameRequired">Both first and last name required</Trans>
	);
	return yup
		.string()
		.matches(fullNameRegex, { message, excludeEmptyString: true })
		.required(<Trans i18nKey="helperText.fullNameRequired">Full Name is required</Trans>);
}

export function requiredTrackingNo() {
	const message = (
		<Trans i18nKey="helperText.trackingNoMustMatch">
			May only contain alphanumeric characters and spaces.
		</Trans>
	);
	return yup
		.string()
		.matches(alphanumericWithSpacesRegex, { message, excludeEmptyString: true })
		.required(
			<Trans i18nKey="helperText.trackingNoRequired">Tracking Number is required.</Trans>
		);
}

export function requiredCarrierName() {
	return yup
		.string()
		.required(<Trans i18nKey="helperText.carrierRequired">Carrier is required.</Trans>);
}

export function receiptNo() {
	return yup.string().matches(orderReceiptNoRegEx, {
		excludeEmptyString: true,
		message: <Trans i18nKey="OrderLookup.error.orderNoInvalid">Order number is invalid</Trans>
	});
}

const countryByCode = inputData.reduce((acc, country) => {
	acc[country.code] = country;
	return acc;
});

export function address() {
	return yup.object().shape({
		address1: yup
			.string()
			.required(
				<Trans i18nKey="helperText.address1Required">Street Address is required</Trans>
			),
		address2: yup.string(),
		country: yup.string().required(),
		city: yup
			.string()
			.required(<Trans i18nKey="helperText.cityRequired">City is Required</Trans>),
		state: yup.string().when(["country", "$shippable"], {
			is: (country, shippable) => {
				const required = countryByCode[country]?.fields.state?.required;
				return required === "always" || (required === "isShippable" && shippable);
			},
			then: schema => schema.required()
		}),
		zip: yup.string().when(["country", "$shippable"], ([country, shippable]) => {
			// eslint-disable-next-line
			const zipRegex = countryByCode[country]?.zipRegex;
			const required = countryByCode[country]?.fields?.zip?.required;

			const message = (
				<Trans i18nKey="helperText.invalidZip">
					Please verify that you correctly entered your zip or postal code
				</Trans>
			);

			let schema = yup.string();
			if (zipRegex) {
				schema = schema.matches(zipRegex, { message, excludeEmptyString: true });
			}
			if (required === "always" || (required === "isShippable" && shippable)) {
				schema = schema.required(message);
			}
			return schema;
		})
	});
}
