import { DSPalette } from "@clickbank-ui/seller-design-system";
import { Check, FileCopy } from "@mui/icons-material";
import { Box, Link, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Trans } from "react-i18next";

const { palette } = DSPalette;

const styles = {
	toast: {
		position: "absolute",
		top: 0,
		left: 8,
		padding: "2px 6px",
		background: palette.success.light,
		borderRadius: 4,

		"& .MuiTypography-root": {
			display: "flex",
			fontSize: 12,
			color: palette.success.dark,
			fontWeight: 600,

			"& svg": {
				marginLeft: 0,
				paddingRight: "2px",
				fontSize: "16px",
				color: palette.success.dark
			}
		}
	}
};

export const CopyableLink = ({ height, url, width }) => {
	const [copySuccess, setCopySuccess] = useState(null);

	const handleCopy = async () => {
		await navigator.clipboard.writeText(url);
		setCopySuccess(prev => {
			if (prev) {
				clearTimeout(prev);
			}
			return setTimeout(() => setCopySuccess(null), 3000);
		});
	};

	return (
		<Box sx={{ position: "relative" }}>
			<Link onClick={handleCopy} sx={{ flexShrink: 0 }}>
				<FileCopy sx={{ height: height, width: width }} />
			</Link>
			{copySuccess != null && (
				<Box sx={styles.toast}>
					<Typography variant="body2">
						<Check />
						<Trans i18nKey="Common.copied">Copied</Trans>
					</Typography>
				</Box>
			)}
		</Box>
	);
};

CopyableLink.propTypes = {
	height: PropTypes.string,
	url: PropTypes.string.isRequired,
	width: PropTypes.string
};
export default CopyableLink;
