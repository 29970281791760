import * as yup from "yup";
import { DSPalette } from "@clickbank-ui/seller-design-system";
import { DSBreakpoints } from "@clickbank-ui/seller-design-system";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChevronLeft } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Grid, Typography } from "@mui/material";
import React, { createRef, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";

import { resendSignUp, signUp } from "../../Api/Amplify";
import { ControlledTextField } from "../../components/ControlledTextField";
import MaintenanceMessage from "../../components/MaintenanceMessage/MaintenanceMessage";
import PasswordInput from "../../components/PasswordInput";
import PasswordValidator from "../../components/PasswordValidator/PasswordValidator";
import Recaptcha from "../../components/Recaptcha";
import assetPath from "../../config/config.js";
import useMaintenanceMode from "../../util/useMaintenanceMode";
import { name, passwordRegex, requiredEmail } from "../../util/validation";

const breakpoints = DSBreakpoints.default;
const { palette } = DSPalette;

const styles = {
	overLapGrid: {
		minHeight: "270px",
		marginLeft: "-70px",
		marginRight: "-60px"
	},
	textFieldBox: {
		width: "100%",

		[breakpoints.up("sm")]: {
			width: "480px"
		},
		"& .backButton": {
			marginTop: "16px"
		}
	},
	textBox: {
		zIndex: 4,
		width: "100%",

		[breakpoints.up("sm")]: {
			maxWidth: "580px"
		},
		"& .MuiAlert-root": {
			marginTop: "2rem"
		}
	},
	recaptcha: {
		height: 65,
		marginTop: "24px",

		[breakpoints.up("md")]: {
			marginTop: "40px"
		}
	}
};

const schema = yup.object().shape({
	name: name(),
	email: requiredEmail(),
	password: yup
		.string()
		.matches(
			passwordRegex,
			<Trans i18nKey="CreateAccountPage.passwordHelperText">
				Please create a password that includes the following criteria:
			</Trans>
		)
});

export default () => {
	const history = useHistory();
	const recaptchaRef = createRef();
	const logo60 = assetPath.concat("images/Frame_60_1.png");

	const [confirmationSent, setConfirmationSent] = useState(null);
	const [loading, setLoading] = useState(false);
	const [resendAlert, setResendAlert] = useState(undefined);
	const [error, setError] = useState(undefined);

	const maintenanceMode = useMaintenanceMode();

	const getCognitoErrorCode = error => {
		const { code } = error;
		if (code === "UsernameExistsException") {
			return (
				<Trans i18nKey="Cognito.UsernameExistsException">
					An account with this email address already exists, please log in to continue.
				</Trans>
			);
		}
		return (
			<Trans i18nKey="Common.error.unknown">
				An unknown error has occurred. Please contact customer support directly.
			</Trans>
		);
	};

	const onSubmit = async ({ email, password, name }) => {
		let token;

		//Recaptcha Logic - disable-recaptcha is used by automated tests in tst to skip recaptcha
		const disableRecaptcha = localStorage.getItem("disable-recaptcha");
		if (disableRecaptcha !== "true") {
			token = recaptchaRef.current.getValue();
			if (!token) {
				try {
					token = await recaptchaRef.current.executeAsync();
				} catch (error) {
					if (recaptchaRef.current) recaptchaRef.current.reset();
					setError(
						<Trans i18nKey="CreateAccountPage.recaptchaFailed">
							An authentication error occurred, please refresh the page and try again.
						</Trans>
					);
					return;
				}
			}
		}

		setLoading(true);
		try {
			await signUp({ email, password, name, token });
			setConfirmationSent(email);
		} catch (error) {
			if (recaptchaRef.current) {
				recaptchaRef.current.reset();
			}
			setError(getCognitoErrorCode(error));
		}
		setLoading(false);
	};

	const form = useForm({
		resolver: yupResolver(schema),
		mode: "onTouched"
	});

	const submit = form.handleSubmit(onSubmit);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const emailParam = urlParams.get("email");
		const nameParam = urlParams.get("name");
		if (emailParam) {
			form.setValue("email", emailParam, { shouldTouch: true });
		}
		if (nameParam) {
			form.setValue("name", nameParam, { shouldTouch: true });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Grid container>
			<Grid item sx={styles.textBox} sm>
				{confirmationSent ? null : (
					<Box>
						{!maintenanceMode.loading && (
							<MaintenanceMessage
								active={maintenanceMode.active}
								pre={maintenanceMode.pre}
							/>
						)}
						<Typography variant="h2">
							<Trans i18nKey="CreateAccountPage.createCustomerAccount">
								Create a Customer Account
							</Trans>
						</Typography>
						<Typography variant="body1" sx={{ marginTop: "20px" }}>
							<Trans i18nKey="CreateAccountPage.mainParagraph">
								Create a customer account if you're a frequent ClickBank customer.
								You can log in to your customer account to view and manage your
								order history.
							</Trans>
						</Typography>

						{!!error && <Alert severity="error">{error}</Alert>}

						<Box sx={styles.textFieldBox}>
							<div
								className="ms-form"
								style={
									maintenanceMode.active
										? { pointerEvents: "none", opacity: "0.4" }
										: {}
								}
							>
								<ControlledTextField
									control={form.control}
									autoComplete="name"
									id="name"
									name="name"
									label={
										<Trans i18nKey="Common.fullNameOptional">
											Full Name (Optional)
										</Trans>
									}
									fullWidth
									sx={{ marginTop: "20px" }}
									InputLabelProps={{
										style: { color: palette.text.disabled }
									}}
								/>
								<ControlledTextField
									control={form.control}
									autoComplete="email"
									id="email"
									name="email"
									label={
										<Trans i18nKey="Common.emailAddress">Email Address</Trans>
									}
									fullWidth
								/>
								<Controller
									control={form.control}
									name="password"
									render={({ field, fieldState }) => (
										<>
											<PasswordInput
												{...field}
												error={!!fieldState?.error}
												id="password"
												label={
													<Trans i18nKey="Common.password">
														Password
													</Trans>
												}
												helperText={
													<Trans i18nKey="CreateAccountPage.passwordHelperText">
														Please create a password that includes the
														following criteria:
													</Trans>
												}
												onKeyPress={e => {
													if (e.key === "Enter" && !loading) {
														submit();
													}
												}}
											/>
											<PasswordValidator password={field.value} />
										</>
									)}
								/>

								<Grid container direction="column">
									<LoadingButton
										fullWidth
										id="createAcctButton"
										disabled={loading}
										onClick={submit}
										loading={loading}
									>
										<Trans i18nKey="CreateAccountPage.createAccount">
											Create Account
										</Trans>
									</LoadingButton>
									<Button
										fullWidth
										color="secondary"
										sx={{ marginTop: "16px" }}
										startIcon={<ChevronLeft />}
										id="backtoOrderLookup"
										onClick={() => {
											history.push("/");
										}}
									>
										<Trans i18nKey="Common.backToOrderLookup">
											Back to Order Lookup
										</Trans>
									</Button>
								</Grid>
								<Recaptcha forwardRef={recaptchaRef} style={styles.recaptcha} />
							</div>
						</Box>
					</Box>
				)}

				{confirmationSent && (
					<Box sx={styles.textFieldBox}>
						<Typography variant="h2">
							<Trans i18nKey="CreateAccountPage.thankYou">Thank You!</Trans>
						</Typography>
						<Typography variant="body1">
							<Trans i18nKey="CreateAccountPage.almostDone">
								You’re almost done! A confirmation email has been sent to{" "}
								<strong>{{ email: confirmationSent }}</strong>. Please verify your
								email address.
							</Trans>
						</Typography>

						{(!!resendAlert || !!error) && (
							<Alert severity={error ? "error" : "success"}>
								{resendAlert || error}
							</Alert>
						)}

						<Grid container direction="column">
							<LoadingButton
								id="resendCreateButton"
								disabled={loading}
								sx={{ marginTop: "20px" }}
								onClick={async () => {
									setLoading(true);
									try {
										await resendSignUp(confirmationSent);
										setResendAlert(
											<Trans i18nKey="CreateAccountPage.resendSignUpSuccess">
												Email successfully re-sent!
											</Trans>
										);
										setTimeout(() => setResendAlert(undefined), 5000);
									} catch (error) {
										setError(
											<Trans i18nKey="CreateAccountPage.resendSignUpFailure">
												An unknown error has occurred. Please contact
												customer support directly.
											</Trans>
										);
										setTimeout(() => setError(undefined), 5000);
									}

									setLoading(false);
								}}
								loading={loading}
							>
								<Trans i18nKey="CreateAccountPage.resendCreateButton">
									Resend Verification Email
								</Trans>
							</LoadingButton>
							<Button
								fullWidth
								color="secondary"
								className="backButton"
								startIcon={<ChevronLeft />}
								id="backtoOrderLookup"
								onClick={() => {
									history.push("/");
								}}
							>
								<Trans i18nKey="Common.backToOrderLookup">
									Back to Order Lookup
								</Trans>
							</Button>
						</Grid>
					</Box>
				)}
			</Grid>

			<Grid item sx={{ display: { xs: "none", md: "flex" } }} sm>
				<Box display="flex" alignSelf="center" alignItems="center" sx={styles.overLapGrid}>
					<img src={logo60} alt="Customer Care" width="100%" height="100%"></img>
				</Box>
			</Grid>
		</Grid>
	);
};
