import { useLazyQuery } from "@apollo/client";
import { DSPalette } from "@clickbank-ui/seller-design-system";
import { DSBreakpoints } from "@clickbank-ui/seller-design-system";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, Divider, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";

import { GEO_CURRENCIES } from "../../Api";
import { countryList } from "../../constants";
import { isRefundAuthFailState } from "../../util/orderHelpers";
import getRandomInt from "../../util/randomID";
import ContactInfo from "../EditInfoModal/ContactInfo";
import PaymentInfo from "../EditInfoModal/PaymentInfo";
import ShippingInfo from "../EditInfoModal/ShippingInfo";
import TaxInfo from "../EditInfoModal/TaxInfo";

const breakpoints = DSBreakpoints.default;
const { palette } = DSPalette;

const styles = {
	billingSameShipping: {
		fontStyle: "italic",
		fontSize: "1rem",
		marginTop: "14px"
	},
	contactInfo: {
		color: palette.text.primary,
		fontWeight: "400",
		marginLeft: "100px",

		[breakpoints.up("md")]: {
			marginLeft: "0"
		}
	},
	container: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "flex-start",
		gap: "2rem",
		marginTop: "2rem"
	},
	editButton: {
		maxWidth: 126,
		minWidth: 100,
		marginTop: "16px"
	},
	section: {
		display: "flex",
		flexDirection: "column",
		minWidth: 200,

		"& .MuiTypography-root": {
			lineHeight: 1.2,

			[breakpoints.up("md")]: {
				lineHeight: 1
			}
		}
	},
	sellerAndCustomerInfoHeading: {
		margin: "24px 0",

		[breakpoints.up("md")]: {
			margin: "16px 0 0"
		}
	}
};

const CustomerInformation = ({ orderDetailsInfo }) => {
	const [acceptedCards, setAcceptedCards] = useState([]);
	const [countryCodeList, setCountryCodeList] = useState([]);
	const [buttonClicked, setButtonClicked] = useState(false);
	const [editInfoType, setEditInfoType] = useState("contactInfo");
	const [isEditModalOpen, setEditModalOpen] = useState(false);

	const skus = orderDetailsInfo?.lineItems?.map(elem => elem.product.itemNo);
	const countryCode = orderDetailsInfo?.shippingCustomer?.country;
	const usTerritoryCodes = useMemo(() => ["GU", "PR", "VI", "AS", "MP", "MH", "PW"], []);
	const currencyCode = orderDetailsInfo?.currencyCode;
	const vendorId =
		!!orderDetailsInfo?.lineItems && orderDetailsInfo?.lineItems.length > 0
			? orderDetailsInfo?.lineItems[0]?.product?.vendor?.nickname
			: undefined;

	const handleEditModalClose = () => setEditModalOpen(false);

	const isProductRecurring = orderDetailsInfo?.lineItems?.some(element => element.recurring);
	const isProductActive = orderDetailsInfo?.lineItems?.some(
		element => element.paymentPlan?.activeStatus === "ACTIVE"
	);
	const hasRebills = orderDetailsInfo?.rebills?.length > 1;
	const isProductPhysical = orderDetailsInfo?.lineItems?.some(
		element => element.product?.shippable
	);

	const showTaxInfo =
		orderDetailsInfo?.shippingCustomer?.country !== "US" && orderDetailsInfo?.taxes > 0;

	const isShippingValid = orderDetailsInfo?.lineItems?.some(element => {
		if (
			["STANDARD_PHYSICAL", "STANDARD_DIGITAL_STANDARD_PHYSICAL"].includes(
				element.product?.orderType
			)
		)
			return element.shippingNotice === null;
		else if (
			"STANDARD_PHYSICAL_RECURRING_DIGITAL" === element.product?.orderType &&
			!hasRebills
		)
			return element.shippingNotice === null;
		else if (element.product?.orderType === "RECURRING_PHYSICAL")
			return element.paymentPlan.activeStatus === "ACTIVE";
		return false;
	});

	const allowPaymentEdit =
		((isProductRecurring && isProductActive) ||
			isRefundAuthFailState(orderDetailsInfo, null)) &&
		!["PYPL", "PAYPAL_ADAPTIVE", "PAYPAL_COMMERCE"].includes(orderDetailsInfo?.paymentMethod);

	const isShippingSameAsBilling = () => {
		if (!isProductPhysical) {
			return false;
		}
		return (
			orderDetailsInfo?.shippingCustomer?.address1 ===
				orderDetailsInfo?.billingCustomer?.address1 &&
			orderDetailsInfo?.shippingCustomer?.address2 ===
				orderDetailsInfo?.billingCustomer?.address2 &&
			orderDetailsInfo?.shippingCustomer?.city === orderDetailsInfo?.billingCustomer?.city &&
			orderDetailsInfo?.shippingCustomer?.state ===
				orderDetailsInfo?.billingCustomer?.state &&
			orderDetailsInfo?.shippingCustomer?.zip === orderDetailsInfo?.billingCustomer?.zip &&
			orderDetailsInfo?.shippingCustomer?.country ===
				orderDetailsInfo?.billingCustomer?.country
		);
	};

	const geoCurrenciesSuccessFunction = useCallback(
		data => {
			if (!buttonClicked) return;
			///Shipping

			const geoCurrencies = data.geoCurrencies;
			const countryCodeArray = Array.from(geoCurrencies?.countries, x => x.countryCode);
			if (!countryCodeArray.includes(countryCode) && usTerritoryCodes.includes(countryCode)) {
				countryCodeArray.push(countryCode);
			}
			countryCodeArray.sort((a, b) => countryList[a]?.localeCompare(countryList[b]));
			setCountryCodeList(countryCodeArray);
			["shippingInfo", "taxInfo"].includes(editInfoType) && setEditModalOpen(true);
			///Payment
			const eligibleGeocurrencies = geoCurrencies?.countries.filter(
				item => item.countryCode === countryCode
			);

			!!eligibleGeocurrencies &&
				eligibleGeocurrencies.length > 0 &&
				eligibleGeocurrencies[0].currencies.forEach(item => {
					if (item.key === "CREDIT_CARD") {
						const eligibleCards = item.value.filter(
							item2 => item2.code === currencyCode
						);
						let acceptedCards = [];

						if (!eligibleCards[0]?.accepted || eligibleCards[0]?.accepted.length < 1) {
							// no rules found; use default
							acceptedCards = ["masterCard", "visa"];
							if (currencyCode === "USD") {
								acceptedCards.concat(["diners", "discover", "americanExpress"]);
							}
						} else {
							acceptedCards = eligibleCards[0].accepted;
						}
						setAcceptedCards(acceptedCards);
						(editInfoType === "paymentInfoCC" || editInfoType === "paymentInfoPP") &&
							setEditModalOpen(true);
					}
				});
			setButtonClicked(false);
		},
		[countryCode, usTerritoryCodes, currencyCode, editInfoType, buttonClicked]
	);

	const geoErrorFunction = () => {
		// create fake geocurrencies data response with only the order's current country
		let acceptedCards = ["masterCard", "visa"];
		if (currencyCode === "USD") {
			acceptedCards.concat(["diners", "discover", "americanExpress"]);
		}
		const data = {
			geoCurrencies: {
				countries: [
					{
						// if code matches a US territory, default to US instead, and the territory will be added by geoCurrenciesSuccessFunction
						countryCode: usTerritoryCodes.includes(countryCode) ? "US" : countryCode,
						currencies: [
							{
								key: "CREDIT_CARD",
								value: [
									{
										accepted: acceptedCards,
										code: currencyCode
									}
								]
							}
						]
					}
				]
			}
		};
		geoCurrenciesSuccessFunction(data);
	};

	const [
		getGeoCurrencies,
		{ data: geoData, loading: geoLoading, called: geoCalled, error: geoError }
	] = useLazyQuery(GEO_CURRENCIES, {
		variables: { vendorId: vendorId, skus: skus },
		context: {
			noAuth: true
		},
		onError: geoErrorFunction
	});

	useEffect(() => {
		if (!geoLoading && geoData && !geoError) {
			geoCurrenciesSuccessFunction(geoData);
		}
	}, [geoCurrenciesSuccessFunction, geoData, geoError, geoLoading]);

	return (
		<Box>
			<Divider />
			<Typography variant="h3" sx={styles.sellerAndCustomerInfoHeading}>
				<Trans i18nKey="OrderDetails.customerInfo">Customer Information</Trans>
			</Typography>

			<Dialog
				open={isEditModalOpen}
				maxWidth="sm"
				onClose={handleEditModalClose}
				aria-labelledby="edit-modal"
				aria-describedby="edit-user-info"
				disablePortal // mouseflow
			>
				<>
					{editInfoType === "paymentInfoCC" || editInfoType === "paymentInfoPP" ? (
						<PaymentInfo
							infoType={editInfoType}
							handleClose={handleEditModalClose}
							orderDetailsInfo={orderDetailsInfo}
							country={countryCode}
							acceptedCards={acceptedCards}
							hasRebills={hasRebills}
							isRefundAuthFailState={isRefundAuthFailState(orderDetailsInfo, null)}
							countryCodeList={countryCodeList}
							isShippingSameAsBilling={isShippingSameAsBilling()}
							isShippable={isProductPhysical}
						/>
					) : editInfoType === "shippingInfo" ? (
						<ShippingInfo
							handleClose={handleEditModalClose}
							orderDetailsInfo={orderDetailsInfo}
							countryCodeList={countryCodeList}
							hasRebills={hasRebills}
							isShippingSameAsBilling={isShippingSameAsBilling()}
							allowBilling={allowPaymentEdit}
						/>
					) : editInfoType === "contactInfo" ? (
						<ContactInfo
							handleClose={handleEditModalClose}
							email={orderDetailsInfo?.billingCustomer?.email}
							phoneNumber={orderDetailsInfo?.billingCustomer?.phone}
							phoneOnOrder={orderDetailsInfo?.phoneNumberOnOrder}
							receipt={orderDetailsInfo?.receiptNo}
							fullName={orderDetailsInfo?.billingCustomer?.fullName}
							hasRebills={hasRebills}
						/>
					) : (
						<TaxInfo
							handleClose={handleEditModalClose}
							orderDetailsInfo={orderDetailsInfo}
							countryCodeList={countryCodeList}
							hasRebills={hasRebills}
						/>
					)}
				</>
			</Dialog>

			<Box sx={styles.container}>
				<Box sx={styles.section}>
					<Typography variant="h5" fontWeight="600">
						<Trans i18nKey="OrderDetails.contactTitle">Contact</Trans>
					</Typography>
					<Box sx={{ marginTop: "20px", wordBreak: "break-word" }}>
						<div className="no-mouseflow">
							<Typography>{orderDetailsInfo?.billingCustomer?.email}</Typography>
							{orderDetailsInfo?.billingCustomer?.phone && (
								<Typography>{orderDetailsInfo?.billingCustomer?.phone}</Typography>
							)}
						</div>
					</Box>
					<Button
						size="small"
						color="secondary"
						id={"edit1" + getRandomInt()}
						onClick={() => {
							setButtonClicked(true);
							setEditInfoType("contactInfo");
							setEditModalOpen(true);
						}}
						sx={styles.editButton}
					>
						<Trans i18nKey="OrderDetails.editText">Edit</Trans>
					</Button>
				</Box>
				{isProductPhysical && (
					<Box sx={styles.section}>
						<Typography variant="h5" fontWeight="600">
							<Trans i18nKey="OrderDetails.shipping">Shipping</Trans>
						</Typography>
						<Box sx={{ marginTop: "20px", wordBreak: "break-word" }}>
							<div className="no-mouseflow">
								<Typography sx={{ fontWeight: "400" }}>
									{orderDetailsInfo?.shippingCustomer?.fullName}
								</Typography>
								<Typography>
									{orderDetailsInfo?.shippingCustomer?.address1}
								</Typography>
								{orderDetailsInfo?.shippingCustomer?.address2 && (
									<Typography>
										{orderDetailsInfo?.shippingCustomer?.address2}
									</Typography>
								)}
								<Typography>
									{orderDetailsInfo?.shippingCustomer?.city}
									{orderDetailsInfo?.shippingCustomer?.city &&
										orderDetailsInfo?.shippingCustomer?.state &&
										", "}
									{orderDetailsInfo?.shippingCustomer?.state}{" "}
									{orderDetailsInfo?.shippingCustomer?.zip}
								</Typography>
							</div>
						</Box>
						{isShippingValid && (
							<LoadingButton
								size="small"
								color="secondary"
								sx={styles.editButton}
								id={"editButton" + getRandomInt()}
								onClick={() => {
									setButtonClicked(true);
									setEditInfoType("shippingInfo");
									if (!geoCalled) getGeoCurrencies();
									else setEditModalOpen(true);
								}}
								loading={geoLoading && editInfoType === "shippingInfo"}
							>
								<Trans i18nKey="OrderDetails.editText">Edit</Trans>
							</LoadingButton>
						)}
					</Box>
				)}
				<Box sx={styles.section}>
					<Typography variant="h5" fontWeight="600">
						<Trans i18nKey="OrderDetails.billing">Billing</Trans>
					</Typography>
					<Box sx={{ marginTop: "20px", wordBreak: "break-word" }}>
						<div className="no-mouseflow">
							<Typography sx={{ fontWeight: "400" }}>
								{["PYPL", "PAYPAL_ADAPTIVE", "PAYPAL_COMMERCE"].includes(
									orderDetailsInfo?.paymentMethod
								) ? (
									<Trans i18nKey="OrderDetails.paypal">PayPal</Trans>
								) : orderDetailsInfo?.last4 ? (
									<>
										<Trans i18nKey="OrderDetails.card">
											{{
												cardType: orderDetailsInfo?.paymentMethod.substring(
													0,
													4
												)
											}}{" "}
											ending in {{ last4: orderDetailsInfo?.last4 }}
										</Trans>
										{/* Remove when Apple Pay CC payment change is allowed */}
										{orderDetailsInfo?.paymentMethod.endsWith("APPLE") && (
											<span>&nbsp;(Apple Pay)</span>
										)}
									</>
								) : (
									orderDetailsInfo?.paymentMethod
								)}
							</Typography>
						</div>
						{isShippingSameAsBilling() ? (
							<Typography
								sx={styles.billingSameShipping}
								i18nkey="OrderDetails.ShippingSameAsBilling"
							>
								Billing Address same as Shipping
							</Typography>
						) : (
							<Box sx={{ marginTop: "20px", wordBreak: "break-word" }}>
								<div className="no-mouseflow">
									<Typography sx={{ fontWeight: "400" }}>
										{orderDetailsInfo?.billingCustomer?.fullName}
									</Typography>
									<Typography>
										{orderDetailsInfo?.billingCustomer?.address1}
									</Typography>
									{orderDetailsInfo?.billingCustomer?.address2 && (
										<Typography>
											{orderDetailsInfo?.billingCustomer?.address2}
										</Typography>
									)}
									<Typography>
										{orderDetailsInfo?.billingCustomer?.city}
										{orderDetailsInfo?.billingCustomer?.city &&
											orderDetailsInfo?.billingCustomer?.state &&
											", "}
										{orderDetailsInfo?.billingCustomer?.state}{" "}
										{orderDetailsInfo?.billingCustomer?.zip}
									</Typography>
								</div>
							</Box>
						)}
						{isRefundAuthFailState(orderDetailsInfo, null) && (
							<Typography
								sx={{
									color: palette.error.dark,
									fontSize: "14px",
									fontWeight: "400"
								}}
							>
								<Trans i18nKey="OrderDetails.errorProcessingRefund">
									Error processing refund. Please update payment information.
								</Trans>
							</Typography>
						)}
					</Box>
					{allowPaymentEdit && (
						<LoadingButton
							size="small"
							color="secondary"
							sx={styles.editButton}
							id={"editButton2" + getRandomInt()}
							onClick={() => {
								setEditInfoType("paymentInfoCC");
								setButtonClicked(true);
								if (geoCalled) {
									acceptedCards.length > 0 && setEditModalOpen(true);
								} else getGeoCurrencies();
							}}
							loading={
								geoLoading &&
								(editInfoType === "paymentInfoCC" ||
									editInfoType === "paymentInfoPP")
							}
						>
							<Trans i18nKey="OrderDetails.editText">Edit</Trans>
						</LoadingButton>
					)}
				</Box>
				{showTaxInfo && (
					<Box sx={styles.section}>
						<Typography variant="h5" fontWeight="600">
							<Trans i18nKey="OrderDetails.taxInformation">Tax Information</Trans>
						</Typography>
						<Box sx={{ marginTop: "20px", wordBreak: "break-word" }}>
							<div className="no-mouseflow">
								<Typography sx={{ fontWeight: "400" }}>
									{orderDetailsInfo?.taxAddress?.name}
								</Typography>
								<Typography>{orderDetailsInfo?.taxAddress?.address1}</Typography>
								{orderDetailsInfo?.taxAddress?.address2 && (
									<Typography>
										{orderDetailsInfo?.taxAddress?.address2}
									</Typography>
								)}
								<Typography>
									{orderDetailsInfo?.taxAddress?.city}
									{orderDetailsInfo?.taxAddress?.city &&
										orderDetailsInfo?.taxAddress?.state &&
										", "}
									{orderDetailsInfo?.taxAddress?.state}{" "}
									{orderDetailsInfo?.taxAddress?.zip}
								</Typography>
								<Typography>{orderDetailsInfo?.taxAddress?.country}</Typography>
								<Typography>{orderDetailsInfo?.taxAddress?.taxId}</Typography>
							</div>
						</Box>
						<LoadingButton
							size="small"
							color="secondary"
							sx={styles.editButton}
							id={"editButton" + getRandomInt()}
							onClick={() => {
								setButtonClicked(true);
								setEditInfoType("taxInfo");
								if (!geoCalled) getGeoCurrencies();
								else setEditModalOpen(true);
							}}
							loading={geoLoading && editInfoType === "taxInfo"}
						>
							<Trans i18nKey="OrderDetails.editText">Edit</Trans>
						</LoadingButton>
					</Box>
				)}
			</Box>
		</Box>
	);
};

CustomerInformation.propTypes = {
	orderDetailsInfo: PropTypes.object
};

export default CustomerInformation;
