import * as yup from "yup";
import { DSPalette } from "@clickbank-ui/seller-design-system";
import { DSBreakpoints } from "@clickbank-ui/seller-design-system";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChevronLeft } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
	changePassword,
	getCurrentUserDetails,
	signOut,
	updateUserAttributes
} from "../../Api/Amplify";
import { ControlledTextField } from "../../components/ControlledTextField";
import PasswordInput from "../../components/PasswordInput";
import PasswordValidator from "../../components/PasswordValidator/PasswordValidator";
import assetPath from "../../config/config.js";
import { getCognitoErrorCode } from "../../util/cognitoErrors";
import { name, passwordRegex } from "../../util/validation";

const breakpoints = DSBreakpoints.default;
const { palette } = DSPalette;

const styles = {
	overLapGrid: {
		minHeight: "270px",
		marginTop: "200px",
		marginLeft: " -70px",
		marginRight: "-60px",

		"& img": {
			width: "100%",
			height: "auto"
		}
	},
	textBox: {
		zIndex: 4,
		width: "100%",

		[breakpoints.up("sm")]: {
			maxWidth: "580px"
		},
		"& .MuiAlert-root": {
			marginTop: "2rem"
		}
	},
	textFieldBox: {
		width: "100%",

		[breakpoints.up("sm")]: {
			width: "480px"
		},
		"& .backButton": {
			marginTop: "16px"
		},
		"& .updateButton": {
			marginTop: "8px"
		},
		"&.MuiButton-sizeSmall": {
			width: "fit-content"
		}
	}
};

const nameSchema = yup.object().shape({
	name: name()
});

const passwordSchema = yup.object().shape({
	currentPassword: yup
		.string()
		.matches(
			passwordRegex,
			<Trans i18nKey="ManageAccount.passwordValid">
				Please enter your current password and ensure it is valid.
			</Trans>
		),
	newPassword: yup
		.string()
		.matches(
			passwordRegex,
			<Trans i18nKey="CreateAccountPage.passwordHelperText">
				Please create a password that includes the following criteria:
			</Trans>
		)
});

export default () => {
	const history = useHistory();
	const logo60 = assetPath.concat("images/Frame_60_1.png");

	const isXs = useMediaQuery("(maxWidth:599px)");

	const [alert, setAlert] = useState(undefined);
	const [loading, setLoading] = useState(false);

	const [email, setEmail] = useState("");

	const nameForm = useForm({
		resolver: yupResolver(nameSchema),
		mode: "onTouched",
		defaultValues: { name: "" }
	});

	const passwordForm = useForm({
		resolver: yupResolver(passwordSchema),
		mode: "onTouched",
		defaultValues: { currentPassword: "", newPassword: "" }
	});

	const getUser = async () => {
		const user = await getCurrentUserDetails();
		if (user && user.attributes) {
			const { email, name } = user.attributes;
			email && setEmail(email);
			if (name) {
				nameForm.setValue("name", name);
			}
		} else {
			await signOut();
			history.push("/?status=AuthenticationError");
		}
	};

	const smoothScrollTop = () =>
		window.scrollY > (isXs ? 530 : 200) &&
		window.scrollTo({
			top: isXs ? 530 : 200,
			left: 0,
			behavior: "smooth"
		});

	useEffect(() => {
		getUser();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const setNewAlert = (type, text) => {
		setAlert({ type, text, show: true });
		setTimeout(() => setAlert({ ...alert, show: false }), 3000);
	};

	const onNameSubmit = async ({ name }) => {
		setLoading(true);
		try {
			await updateUserAttributes({ name });
			await getUser();
			setNewAlert(
				"success",
				<Trans i18nKey="ManageAccount.nameChangeSuccess">
					Your name was successfully updated.
				</Trans>
			);
		} catch ({ code }) {
			setNewAlert("error", getCognitoErrorCode(code));
		}
		smoothScrollTop();
		setLoading(false);
	};

	const submitName = nameForm.handleSubmit(onNameSubmit);

	const onPasswordSubmit = async ({ currentPassword, newPassword }) => {
		setLoading(true);
		try {
			await changePassword(currentPassword, newPassword);
			setNewAlert(
				"success",
				<Trans i18nKey="ManageAccount.passwordChangeSuccess">
					Your password was successfully updated.
				</Trans>
			);
		} catch ({ code }) {
			setNewAlert("error", getCognitoErrorCode(code));
		}
		smoothScrollTop();
		setLoading(false);
	};

	const submitPassword = passwordForm.handleSubmit(onPasswordSubmit);

	return (
		<Grid container>
			<Grid item sx={styles.textBox} sm>
				<Box>
					<Typography variant="h2">
						<Trans i18nKey="ManageAccount.title">Manage Your Account</Trans>
					</Typography>

					<Box sx={{ marginTop: "20px" }}>
						<Typography>
							<strong style={{ paddingRight: "12px" }}>
								<Trans i18nKey="ManageAccount.emailAddress">Email:</Trans>
							</strong>
							<span className="no-mouseflow">{email}</span>
						</Typography>
					</Box>

					{alert?.show && <Alert severity={alert?.type}>{alert?.text}</Alert>}

					<Box sx={styles.textFieldBox}>
						<ControlledTextField
							control={nameForm.control}
							className="no-mouseflow"
							autoComplete="name"
							name="name"
							id="name"
							label={
								<Trans i18nKey="Common.fullNameOptional">
									Full Name (Optional)
								</Trans>
							}
							fullWidth
							sx={{ marginTop: "20px" }}
							onKeyPress={e => {
								if (e.key === "Enter" && !loading) {
									submitName();
								}
							}}
							InputLabelProps={{
								style: { color: palette.text.disabled }
							}}
						/>
						<LoadingButton
							id="updateNamebutton"
							size="small"
							disabled={loading}
							className="updateButton"
							onClick={submitName}
							loading={loading}
						>
							<Trans i18nKey="ManageAccount.updateFullName">Update Full Name</Trans>
						</LoadingButton>
						<Typography variant="body2" sx={{ margin: "32px 0 8px" }}>
							<Trans i18nKey="ManageAccount.toUpdateYourPassword">
								To reset your password, please input your current password followed
								by your new password.
							</Trans>
						</Typography>

						<Controller
							control={passwordForm.control}
							name="currentPassword"
							render={({ field, fieldState }) => (
								<>
									<PasswordInput
										{...field}
										error={!!fieldState?.error}
										id="current-password"
										label={
											<Trans i18nKey="ManageAccount.currentPassword">
												Current Password
											</Trans>
										}
										helperText={
											<Trans i18nKey="ManageAccount.passwordValid">
												Please enter your current password and ensure it is
												valid.
											</Trans>
										}
									/>
								</>
							)}
						/>

						<Controller
							control={passwordForm.control}
							name="newPassword"
							render={({ field, fieldState }) => (
								<>
									<PasswordInput
										{...field}
										error={!!fieldState?.error}
										id="password"
										label={<Trans i18nKey="Common.password">Password</Trans>}
										helperText={
											<Trans i18nKey="CreateAccountPage.passwordHelperText">
												Please create a password that includes the following
												criteria:
											</Trans>
										}
										onKeyPress={e => {
											if (e.key === "Enter" && !loading) {
												submitPassword();
											}
										}}
									/>
									<PasswordValidator password={field.value} />
								</>
							)}
						/>

						<LoadingButton
							fullWidth
							id="updatePasswordbutton"
							color="primary"
							disabled={loading}
							onClick={submitPassword}
							loading={loading}
						>
							<Trans i18nKey="ManageAccount.updatePassword">Update Password</Trans>
						</LoadingButton>
						<Button
							fullWidth
							color="secondary"
							className="backButton"
							startIcon={<ChevronLeft />}
							id="backButton"
							onClick={() => {
								history.push("/orderHistory");
							}}
						>
							<Trans i18nKey="Common.backToOrderHistory">Back to Order History</Trans>
						</Button>
					</Box>
				</Box>
			</Grid>

			<Grid item sx={{ display: { xs: "none", md: "flex" } }} sm>
				<Box sx={styles.overLapGrid}>
					<img src={logo60} alt="Customer Care"></img>
				</Box>
			</Grid>
		</Grid>
	);
};
