import { useMutation } from "@apollo/client";
import { DSPalette } from "@clickbank-ui/seller-design-system";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormGroup,
	IconButton,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
	Typography
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";

import { ApolloClient, CREATE_TICKET } from "../../Api";
import {
	createTicketTypes,
	getRefundRequestTopics,
	getSubscriptionCancellationTopics,
	getTechSupportTopics,
	supportTicketTopic
} from "../../constants/index";
import { AppContext } from "../../context";

const { cbNeutral } = DSPalette;

const CreateTicketModal = ({
	handleClose,
	ticketType,
	product,
	isSubscriptionCancelable,
	isRefundRequestable,
	lineItemId,
	receipt
}) => {
	const [ticketTypeVal, setTicketTypeVal] = useState(ticketType);
	const [comment, setComment] = useState("");
	const [supportTopic, setSupportTopic] = React.useState("");

	const { setAlertText, setAlert, setActionSuccessful, refetchOrderByReceiptNo } =
		useContext(AppContext);

	const handleError = () => {
		handleClose();
		setAlertText(
			<Trans i18nKey="Common.error.unknown">
				An unknown error has occurred. Please contact customer support directly.
			</Trans>
		);
		setAlert(true);
		setActionSuccessful(false);
	};

	const [createTicket, { loading, called, error }] = useMutation(CREATE_TICKET, {
		variables: {
			receipt,
			lineItemId: parseInt(lineItemId, 10),
			ticketType: createTicketTypes[ticketTypeVal],
			reason: supportTopic,
			comment
		},
		client: ApolloClient,
		onError: handleError
	});

	const handleChangeTicketType = event => {
		setTicketTypeVal(event.target.value);
	};

	const handleChangeProblemType = event => {
		setSupportTopic(event.target.value);
	};

	const handleChangeComment = event => {
		const { value } = event.target;
		if (!value) return setComment("");
		let formattedValue = value;
		if (formattedValue.length > 500) formattedValue = formattedValue.slice(0, 500);
		setComment(formattedValue);
	};

	useEffect(() => {
		setSupportTopic("");
	}, [ticketTypeVal]);

	const supportTopicOptions = useMemo(() => {
		let options = [];
		switch (ticketTypeVal) {
			case "requestRefund":
				options = getRefundRequestTopics(product);
				break;
			case "techSupport":
				options = getTechSupportTopics(product);
				break;
			case "cancelSubscription":
				options = getSubscriptionCancellationTopics(product);
				break;
		}

		return options.map(value => (
			<MenuItem key={value} value={value}>
				{supportTicketTopic[value]}
			</MenuItem>
		));
	}, [ticketTypeVal, product]);

	useEffect(() => {
		if (called && !loading && !error) {
			handleClose();
			setAlertText(
				ticketType === "requestRefund" ? (
					<Trans i18nKey="CreateTicketModal.createRefundSuccess">
						Your refund request has been received. We are currently processing your
						request and will send a confirmation email once the refund has been
						completed.
					</Trans>
				) : ticketType === "cancelSubscription" ? (
					<Trans i18nKey="CreateTicketModal.createCancellationSuccess">
						Your cancellation request has been received. We'll send an email in 5-7
						minutes to let you know your order has been cancelled.
					</Trans>
				) : (
					//techSupport ticket
					<Trans i18nKey="CreateTicketModal.createTechSupportSuccess">
						Your ticket has been created and a notice has been sent to the seller.
						Please allow 2 business days for the seller to respond or for your ticket to
						be processed.
					</Trans>
				)
			);
			setActionSuccessful(true);
			setAlert(true);
			refetchOrderByReceiptNo(receipt);
		}
	}, [
		called,
		loading,
		error,
		handleClose,
		setAlertText,
		setAlert,
		setActionSuccessful,
		receipt,
		refetchOrderByReceiptNo,
		ticketType
	]);

	return (
		<>
			<DialogTitle variant="h4">
				<Trans i18nKey="CreateTicketModal.title.createSupportTicket">
					Create Support Ticket
				</Trans>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: cbNeutral[700]
					}}
				>
					<Close />
				</IconButton>
			</DialogTitle>

			<DialogContent>
				<Typography>
					<Trans i18nKey="CreateTicketModal.para1">
						If you need assistance, please use this form to create a ticket. Please,
						only one ticket per order. If you need assistance with a different order
						please lookup the details for that order.
					</Trans>
				</Typography>
				<Typography>
					<Trans i18nKey="CreateTicketModal.para2">
						You may return to this page at any time to view the status of your ticket or
						to respond to the seller. If the seller resolves your issue, please close
						your ticket.
					</Trans>
				</Typography>

				<FormGroup sx={{ marginTop: "20px" }}>
					<InputLabel disableAnimation>
						<Trans i18nKey="CreateTicketModal.titleTicketType">Ticket Type</Trans>
					</InputLabel>
					<RadioGroup
						aria-label="ticket-type"
						name="ticket"
						value={ticketTypeVal}
						onChange={handleChangeTicketType}
					>
						{isRefundRequestable && (
							<FormControlLabel
								value="requestRefund"
								control={<Radio />}
								label={
									<Trans i18nKey="CreateTicketModal.requestRefund">
										Request Refund
									</Trans>
								}
							/>
						)}
						{isSubscriptionCancelable && (
							<FormControlLabel
								value="cancelSubscription"
								control={<Radio />}
								label={
									<Trans i18nKey="CreateTicketModal.cancelSubscription">
										Cancel Subscription
									</Trans>
								}
							/>
						)}
						<FormControlLabel
							value="techSupport"
							control={<Radio />}
							label={
								<Trans i18nKey="CreateTicketModal.techSupportRequest">
									Tech Support Request
								</Trans>
							}
						/>
					</RadioGroup>
				</FormGroup>

				<FormControl fullWidth variant="filled" sx={{ marginTop: "20px" }}>
					<InputLabel>
						<Trans i18nKey="CreateTicketModal.titleProblem">What is the Problem?</Trans>
					</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={supportTopic}
						onChange={handleChangeProblemType}
					>
						{supportTopicOptions}
					</Select>
				</FormControl>

				<TextField
					fullWidth
					multiline
					minRows={4}
					id="outlined-textarea"
					label={
						<Trans i18nKey="CreateTicketModal.titleComments">Additional Comments</Trans>
					}
					value={comment}
					onChange={handleChangeComment}
					helperText={
						<>
							<Trans i18nKey="Common.characterLimit">Character limit</Trans>:{" "}
							{comment.length}/500
						</>
					}
					sx={{ marginTop: "20px" }}
				/>
			</DialogContent>

			<DialogActions>
				<Button color="secondary" onClick={handleClose}>
					<Trans i18nKey="CreateTicketModal.Discard">Discard</Trans>
				</Button>
				<LoadingButton
					disabled={!supportTopic || loading}
					onClick={createTicket}
					loading={loading}
				>
					<Trans i18nKey="CreateTicketModal.sendToSeller">Send to Seller</Trans>
				</LoadingButton>
			</DialogActions>
		</>
	);
};

CreateTicketModal.propTypes = {
	handleClose: PropTypes.func,
	ticketType: PropTypes.oneOf(["requestRefund", "cancelSubscription", "techSupport"]),
	product: PropTypes.shape({
		orderType: PropTypes.string
	}),
	isSubscriptionCancelable: PropTypes.bool.isRequired,
	isRefundRequestable: PropTypes.bool.isRequired,
	lineItemId: PropTypes.string.isRequired,
	receipt: PropTypes.string.isRequired
};

export default CreateTicketModal;
