async function getMaintenanceMessages() {
	const messagesJson = await fetch(
		"https://cb-stack-ui.s3.us-west-2.amazonaws.com/maintenance-messaging.json"
	)
		.then(response => response.json())
		.then(data => {
			return data;
		})
		.catch(error => {
			console.error(error);
		});
	return messagesJson;
}

export default getMaintenanceMessages;
