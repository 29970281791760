// import { useMutation } from "@apollo/client";
import * as yup from "yup";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DSPalette, DSTypography } from "@clickbank-ui/seller-design-system";
import { yupResolver } from "@hookform/resolvers/yup";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
	Box,
	Button,
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	Paper,
	Typography
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import Linkify from "react-linkify";

import { ApolloClient, CREATE_CUSTOMER_RETURN, INFER_COURIER } from "../../Api";
import { AppContext } from "../../context";
import { requiredCarrierName, requiredTrackingNo } from "../../util/validation";
import { ControlledTextField } from "../ControlledTextField";

const { cbNeutral } = DSPalette;
const { primaryFont } = DSTypography;

const styles = {
	dialogContent: {
		"& .MuiTypography": {
			color: cbNeutral[400]
		}
	},
	loader: {
		position: "absolute",
		top: 33,
		left: "calc(50% - 12px)",
		zIndex: 100
	},
	returnInstructions: {
		maxHeight: "35vh",
		overflowY: "scroll",
		p: 1.5,
		background: cbNeutral[950],
		borderColor: cbNeutral[700],

		"& pre": {
			margin: 0,
			fontFamily: primaryFont,
			fontSize: "1rem",
			color: cbNeutral[400],
			whiteSpace: "pre-line"
		}
	}
};

const schema = yup.object().shape({
	trackingNo: requiredTrackingNo(),
	carrierName: requiredCarrierName()
});

const ReturnProductModal = ({ handleClose, instructions, lineItemId, lineItemReturn }) => {
	const [carrierValue, setCarrierValue] = useState("");

	const { setAlertText, setAlert, setActionSuccessful } = useContext(AppContext);

	const handleError = () => {
		handleClose();
		setAlertText(
			<Trans i18nKey="ReturnProductModal.error.unknown">[ NEED ERROR MESSAGE ]</Trans>
		);
		setAlert(true);
		setActionSuccessful(false);
	};

	const form = useForm({
		resolver: yupResolver(schema),
		mode: "onTouched",
		defaultValues: {
			trackingNo: lineItemReturn?.trackingNumber ?? "",
			carrierName: lineItemReturn?.carrier ?? ""
		}
	});

	const trackingNo = form.watch("trackingNo");

	const [getCarrierName, { loading: carrierLoading }] = useLazyQuery(INFER_COURIER, {
		variables: {
			trackingNumber: trackingNo
		},
		onCompleted: data => {
			const courier = data?.inferCourierFromTrackingNumber;
			if (courier !== null) {
				form.setValue("carrierName", courier);
				setCarrierValue(courier);
			} else {
				form.setValue("carrierName", "");
				setCarrierValue("");
			}
		}
	});

	const changeCarrier = e => {
		setCarrierValue(e.target.value);
	};

	const [
		createCustomerReturn,
		{ called: returnCalled, loading: returnLoading, error: returnError }
	] = useMutation(CREATE_CUSTOMER_RETURN, {
		variables: {
			lineItemId: lineItemId,
			trackingNumber: trackingNo,
			carrier: carrierValue ?? ""
		},
		client: ApolloClient,
		onError: handleError
	});

	const linkifyText = returnText => {
		return (
			<Linkify
				componentDecorator={(decoratedHref, decoratedText, key) => (
					<a target="blank" href={decoratedHref} key={key}>
						{decoratedText}
					</a>
				)}
			>
				<pre>{returnText}</pre>
			</Linkify>
		);
	};

	useEffect(() => {
		if (returnCalled && !returnLoading && !returnError) {
			handleClose();
			setAlertText(
				<Trans i18nKey="ReturnProductModal.alert.success">[ NEED SUCCESS MESSAGE ]</Trans>
			);
			setActionSuccessful(true);
			setAlert(true);
		}
	}, [
		handleClose,
		setAlertText,
		setActionSuccessful,
		setAlert,
		returnCalled,
		returnLoading,
		returnError
	]);

	return (
		<>
			<DialogTitle variant="h4">
				<Trans i18nKey="ReturnProductModal.title.ReturnProduct">Return Product</Trans>
				<IconButton aria-label="close" onClick={handleClose} className="closeBtn">
					<Close />
				</IconButton>
			</DialogTitle>

			<DialogContent sx={styles.dialogContent}>
				{instructions && (
					<>
						<Typography variant="h5" pb={2}>
							<Trans i18nKey="ReturnProductModal.title.returnInstructions">
								Return Instructions
							</Trans>
							:
						</Typography>
						<Paper elevation={0} variant="outlined" sx={styles.returnInstructions}>
							{linkifyText(instructions)}
						</Paper>
						<Divider />
					</>
				)}
				<Typography variant="body2">
					<Trans i18nKey="ReturnProductModal.intro">
						Please provide the following information to the seller. [ NEED FINALIZED
						COPY ]
					</Trans>
				</Typography>
				<ControlledTextField
					fullWidth
					id="trackingNo"
					name="trackingNo"
					sx={{ marginTop: "20px" }}
					label={
						<Trans i18nKey="ReturnProductModal.label.trackingNumber">
							Tracking Number
						</Trans>
					}
					control={form.control}
					onChange={() => {
						// re-trigger validation
						form.trigger("trackingNo");
						getCarrierName();
					}}
					helperText={
						<Trans i18nKey="ReturnProductModal.helperText.trackingNoRequired">
							Tracking Number is required.
						</Trans>
					}
				/>

				<Box sx={{ position: "relative" }}>
					{carrierLoading && (
						<Box sx={styles.loader}>
							<CircularProgress size={24} />
						</Box>
					)}
					<ControlledTextField
						fullWidth
						id="carrierName"
						name="carrierName"
						sx={{ marginTop: "20px" }}
						label={<Trans i18nKey="ReturnProductModal.label.carrier">Carrier</Trans>}
						control={form.control}
						onChange={e => {
							// re-trigger validation
							form.trigger("carrierName");
							changeCarrier(e);
						}}
						helperText={
							<Trans i18nKey="ReturnProductModal.helperText.carrierRequired">
								Carrier is required.
							</Trans>
						}
						inputProps={{ maxLength: 70 }}
						disabled={!trackingNo}
					/>
				</Box>
			</DialogContent>

			<DialogActions>
				<Button onClick={handleClose}>
					<Trans i18nKey="Common.cancel">Cancel</Trans>
				</Button>
				<LoadingButton
					color="primary"
					disabled={!trackingNo || (!trackingNo && !form.carrierName) || returnError}
					onClick={createCustomerReturn}
					loading={returnLoading}
				>
					<Trans i18nKey="ReturnProductModal.button.sendToSeller">Send to Seller</Trans>
				</LoadingButton>
			</DialogActions>
		</>
	);
};

ReturnProductModal.propTypes = {
	handleClose: PropTypes.func,
	instructions: PropTypes.string,
	lineItemId: PropTypes.string,
	lineItemReturn: PropTypes.object
};

export default ReturnProductModal;
