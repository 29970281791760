import { DSBreakpoints, DSPalette } from "@clickbank-ui/seller-design-system";
import { AccountBox, AddCircle, Close, ExitToApp, Menu, Settings } from "@mui/icons-material";
import {
	AppBar,
	Box,
	Container,
	Divider,
	Drawer,
	Fade,
	IconButton,
	Link,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Toolbar,
	Typography
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";

import { signOut } from "../../Api/Amplify";
import { ReactComponent as Logo } from "../../assets/logo/clickBankIcon.svg";
import { disableLogin } from "../../constants";
import { AppContext } from "../../context";
import LanguagePicker from "../LanguagePicker";

const breakpoints = DSBreakpoints.default;
const { cbBrand, cbNeutral, palette } = DSPalette;

const styles = {
	root: {
		height: 64,
		alignItems: "center",
		borderTop: `solid 8px ${palette.primary.main}`,
		boxShadow: "0 4px 8px 0 rgba(14, 13, 38, 0.16)",

		[breakpoints.up("md")]: {
			height: 88
		}
	},
	clkLogo: {
		width: 200,

		[breakpoints.up("md")]: {
			width: 280
		},

		"& a": {
			display: "flex"
		},
		"& svg": {
			width: "inherit",
			verticalAlign: "middle"
		}
	},
	linkDisabled: {
		pointerEvents: "none",
		opacity: "0.4"
	},
	toolbar: {
		justifyContent: "space-between",
		height: 56,

		[breakpoints.up("sm")]: {
			minHeight: 56
		},
		[breakpoints.up("md")]: {
			height: 80
		},
		"& a": {
			fontWeight: 600
		},
		"& .MuiTypography-subtitle1": {
			color: cbNeutral[100]
		}
	},
	toolbarContainer: {
		padding: "0 8px 0 16px",

		[breakpoints.up("sm")]: {
			padding: "0 16px 0 24px"
		},
		[breakpoints.up("md")]: {
			padding: "0 24px"
		},
		"& .MuiDivider-root": {
			alignSelf: "center",
			height: 18,
			margin: "0 1rem",
			borderColor: cbBrand.border,
			borderRightWidth: 2
		}
	}
};

const NavBar = ({ isLoggedIn }) => {
	const history = useHistory();

	const [open, setOpen] = useState(false);

	// For page refresh
	// If sub is set in AppContext (when a cognitoUser is present) there is a current session
	const { sub } = useContext(AppContext);

	const toggleDrawer = () => {
		setOpen(!open);
	};

	return (
		<Box displayPrint="none">
			<AppBar elevation={0} position="fixed" color="secondary" sx={styles.root}>
				<Container maxWidth="lg" sx={styles.toolbarContainer}>
					<Toolbar disableGutters sx={styles.toolbar}>
						<Link href="/" sx={styles.clkLogo}>
							<Fade timeout={800} in>
								<Logo />
							</Fade>
						</Link>
						<Box
							sx={{
								display: {
									xs: "none",
									md: "flex",
									flexDirection: "row"
								}
							}}
						>
							{isLoggedIn || sub ? (
								<>
									<Typography variant="subtitle1">
										<Link onClick={() => history.push("/manageAccount")}>
											<Trans i18nKey="ContactPanel.manageAccountButton">
												Manage Account
											</Trans>
										</Link>
									</Typography>
									<Divider orientation="vertical" flexItem />
									<Typography variant="subtitle1">
										<Link
											onClick={async () => {
												await signOut();
												history.push("/login");
											}}
										>
											<Trans i18nKey="Common.logOut">Log Out</Trans>
										</Link>
									</Typography>
								</>
							) : (
								<>
									<Typography
										variant="subtitle1"
										sx={{
											display: {
												xs: "none",
												md: "block",
												paddingRight: "12px"
											}
										}}
									>
										<Trans i18nKey="NavBar.title.frequentCustomer">
											Frequent Customer?
										</Trans>
									</Typography>
									<Typography
										variant="subtitle1"
										sx={{ marginTop: 0 }}
										className={disableLogin ? `${styles.linkDisabled}` : ""}
									>
										<Link onClick={() => history.push("/login")}>
											<Trans i18nKey="Common.login">Login</Trans>
										</Link>
									</Typography>
									<Divider orientation="vertical" flexItem />
									<Typography
										variant="subtitle1"
										sx={{ marginTop: 0 }}
										className={disableLogin ? `${styles.linkDisabled}` : ""}
									>
										<Link onClick={() => history.push("/createAccount")}>
											<Trans i18nKey="Common.createAnAccount">
												Create an Account
											</Trans>
										</Link>
									</Typography>
								</>
							)}
							<Divider orientation="vertical" flexItem />
							<LanguagePicker />
						</Box>
						<Box
							sx={{
								display: {
									xs: "flex",
									md: "none"
								}
							}}
						>
							<IconButton onClick={toggleDrawer} size="large">
								<Menu />
							</IconButton>
							<Drawer
								open={open}
								anchor="right"
								ModalProps={{ onClose: toggleDrawer }}
							>
								<Toolbar sx={{ height: 56, pr: 1 }}>
									<IconButton onClick={toggleDrawer} size="large">
										<Close />
									</IconButton>
								</Toolbar>
								<List component="nav" onClick={toggleDrawer}>
									{isLoggedIn ? (
										<>
											<ListItem
												onClick={() => history.push("/manageAccount")}
												className={
													disableLogin ? `${styles.linkDisabled}` : ""
												}
											>
												<ListItemIcon>
													<Settings />
												</ListItemIcon>
												<ListItemText>
													<Trans i18nKey="ContactPanel.manageAccountButton">
														Manage Account
													</Trans>
												</ListItemText>
											</ListItem>
											<ListItem
												onClick={async () => {
													await signOut();
													history.push("/login");
												}}
											>
												<ListItemIcon>
													<ExitToApp />
												</ListItemIcon>
												<ListItemText>
													<Trans i18nKey="Common.logOut">Log Out</Trans>
												</ListItemText>
											</ListItem>
										</>
									) : (
										<>
											<ListItem
												onClick={() => history.push("/login")}
												className={
													disableLogin ? `${styles.linkDisabled}` : ""
												}
											>
												<ListItemIcon>
													<AccountBox />
												</ListItemIcon>
												<ListItemText>
													<Trans i18nKey="Common.login">Login</Trans>
												</ListItemText>
											</ListItem>
											<ListItem
												className={
													disableLogin ? `${styles.linkDisabled}` : ""
												}
											>
												<ListItemIcon>
													<AddCircle />
												</ListItemIcon>
												<ListItemText
													onClick={() => history.push("/createAccount")}
												>
													<Trans i18nKey="Common.createAnAccount">
														Create an Account
													</Trans>
												</ListItemText>
											</ListItem>
										</>
									)}
									<Divider sx={{ marginTop: "16px" }} />
									<Box sx={{ padding: "8px 16px 16px 26px" }}>
										<LanguagePicker />
									</Box>
								</List>
							</Drawer>
						</Box>
					</Toolbar>
				</Container>
			</AppBar>
		</Box>
	);
};

NavBar.propTypes = {
	isLoggedIn: PropTypes.bool,
	window: PropTypes.func
};

export default NavBar;
