import { DSPalette } from "@clickbank-ui/seller-design-system";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const { cbNeutral, palette } = DSPalette;

const Status = ({ color = "primary", text, size, style = {} }) => {
	const presetSize = {
		small: "30px",
		medium: "54px"
	};

	const styles = {
		root: {
			padding: "10px 20px",
			borderRadius: "4px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			alignContent: "center",
			minHeight: presetSize[size] ? presetSize[size] : presetSize["medium"],
			background: color === "grey" ? cbNeutral[200] : palette[color].main + "22"
		},
		text: {
			color: color === "grey" ? palette.common.black : palette[color].dark
		}
	};

	return (
		<Box sx={styles.root} style={style}>
			<Typography align="center" sx={styles.text}>
				{text}
			</Typography>
		</Box>
	);
};

Status.propTypes = {
	color: PropTypes.string,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	size: PropTypes.string,
	style: PropTypes.object
};

export default Status;
