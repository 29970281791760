import { DSBreakpoints, DSPalette } from "@clickbank-ui/seller-design-system";
import { Box, Container, Divider, Link, Typography } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";

import { ReactComponent as ClickBankLogo } from "../../assets/logo/clickBankIcon.svg";

const breakpoints = DSBreakpoints.default;
const { cbNeutral, palette } = DSPalette;

const styles = {
	footer: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		marginTop: "2rem",
		alignItems: "center",
		textAlign: "center",

		[breakpoints.up("md")]: {
			alignItems: "start",
			marginTop: "6rem"
		},
		"& a": {
			display: "block",

			[breakpoints.up("md")]: {
				display: "inline"
			}
		},
		"& .MuiDivider-root": {
			display: "none",

			[breakpoints.up("md")]: {
				display: "inline-flex",
				height: "1rem",
				marginRight: ".35rem",
				paddingLeft: ".35rem",
				borderRight: `solid 2px ${cbNeutral[700]}`
			}
		}
	},
	footerBottom: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignSelf: "center",
		alignItems: "center",
		margin: "2rem 0",

		[breakpoints.up("md")]: {
			flexDirection: "row",
			margin: "4rem 0"
		},
		"& .MuiTypography-root": {
			marginTop: 0
		}
	},
	headerFont: {
		fontSize: "22px",
		fontWeight: "bold"
	},
	links: {
		"& a": {
			padding: "0 1rem",
			color: palette.text.primary
		}
	},
	logo: {
		width: 300,
		height: 34,
		marginBottom: "1rem",

		[breakpoints.down("sm")]: {
			margin: "0 auto",
			marginBottom: "2rem"
		}
	}
};

export default () => {
	return (
		<Box displayPrint="none">
			<Container maxWidth="lg" sx={styles.footer}>
				<Box sx={styles.logo}>
					<ClickBankLogo />
				</Box>
				<Box sx={styles.links}>
					<Typography variant="body2">
						<Link href="https://www.clickbank.com/about-clickbank/" color="textPrimary">
							<Trans i18nKey="Footer.linkAboutUs">About Us</Trans>
						</Link>
						<Link href="https://www.clickbank.com/careers/" color="textPrimary">
							<Trans i18nKey="Footer.linkCareers">Careers</Trans>
						</Link>
						<Link href="https://www.clickbank.com/our-story/" color="textPrimary">
							<Trans i18nKey="Footer.linkOurValues">Our Values</Trans>
						</Link>
					</Typography>
				</Box>
				<Box sx={styles.footerBottom}>
					<Typography variant="body2">
						&copy; {new Date().getFullYear()}{" "}
						<Trans i18nKey="Footer.footerNote">ClickBank. All Rights Reserved.</Trans>
						<span style={{ display: { xs: "inline", md: "none" } }}>
							<br />
						</span>
					</Typography>
					<Divider orientation="vertical" />
					<Typography variant="body2">
						<Link href="https://support.clickbank.com/hc/en-us/categories/360002131492-Policies-Legal">
							<Trans i18nKey="Footer.termsOfUse">Terms of Use</Trans>
						</Link>
					</Typography>
					<Divider orientation="vertical" />
					<Typography variant="body2">
						<Link href="https://support.clickbank.com/hc/en-us/articles/360004057131-ClickBank-Privacy-Policy">
							<Trans i18nKey="Footer.privacyPolicy">Privacy Policy</Trans>
						</Link>
					</Typography>
					<Divider orientation="vertical" />
					<Typography variant="body2">
						<Link href="https://support.clickbank.com/hc/en-us/requests/new?ticket_form_id=20575385864084">
							<Trans i18nKey="Footer.reportSeller">Report Seller</Trans>
						</Link>
					</Typography>
				</Box>
			</Container>
		</Box>
	);
};
