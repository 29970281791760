import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

// TODO: Replace with MUI Pagination component

const PaginatedButtonGroup = ({ totalPages = 10, currentPageParent, setCurrentPageParent }) => {
	const [activePages, setActivePages] = useState([1, 2, 3]);
	const [currentPage, setCurrentPage] = useState(currentPageParent);
	const [forwardDirection, setForwardDirection] = useState(true);
	const [initialized, setInitialized] = useState(false);
	const SetCurrentPageFunction = newCurrentPage => {
		setCurrentPage(newCurrentPage);
		if (setCurrentPageParent) setCurrentPageParent(newCurrentPage);
	};

	// this is meant for the order history refetch/reload scenario, to set the active
	// pages to correctly show the range of pages that the user is on, when beyond page 3
	// in a large data set
	useEffect(() => {
		if (!initialized) {
			setInitialized(true);
			if (totalPages > 5) {
				if (currentPage > 3) {
					if (currentPage < totalPages) {
						setActivePages([currentPage - 1, currentPage, currentPage + 1]);
					} else {
						setActivePages([currentPage - 2, currentPage - 1, currentPage]);
					}
					if (currentPage > totalPages - 2) {
						// last 2 pages
						setForwardDirection(false);
					}
				}
			}
		}
	}, [initialized, currentPage, totalPages]);

	const buttonSelectedClass = index => {
		if (forwardDirection) {
			if (index === 4 && currentPage === totalPages) return "Mui-selected";
			if (index === 0 && currentPage === 1) return "Mui-selected";
			if (currentPage === activePages[index]) return "Mui-selected";
		} else if (index - 2 >= 0 && currentPage === activePages[index - 2]) return "Mui-selected";
		return "";
	};

	let tempPages = [];
	if (totalPages <= 5) {
		for (let index = 1; index <= totalPages; index++) {
			tempPages.push(index);
		}
	}
	if (totalPages <= 1) return null;
	if (totalPages <= 5 && totalPages > 1)
		return (
			<Box m={"1rem 0 2rem"}>
				<ToggleButtonGroup>
					<ToggleButton
						id="leftButton"
						value="prev"
						onClick={() => {
							currentPage > 1 && SetCurrentPageFunction(currentPage - 1);
						}}
					>
						<KeyboardArrowLeft />
					</ToggleButton>
					{tempPages.map((x, i) => (
						<ToggleButton
							id={"pageIndex" + i}
							className={currentPage === x ? "Mui-selected" : ""}
							value="page1"
							key={i}
							onClick={() => SetCurrentPageFunction(x)}
						>
							{x}
						</ToggleButton>
					))}

					<ToggleButton
						id="rightButton"
						value="next"
						onClick={() => {
							currentPage < totalPages && SetCurrentPageFunction(currentPage + 1);
						}}
					>
						<KeyboardArrowRight />
					</ToggleButton>
				</ToggleButtonGroup>
			</Box>
		);

	return (
		<Box m={"1rem 0 2rem"}>
			<ToggleButtonGroup>
				<ToggleButton
					id="leftButton"
					value="prev"
					onClick={() => {
						if (activePages[0] - 3 > 0)
							setActivePages(Array.from(activePages, x => x - 3));
						else {
							setForwardDirection(true);
							setActivePages([1, 2, 3]);
						}
						if (currentPage - 3 > 0) SetCurrentPageFunction(currentPage - 3);
						else SetCurrentPageFunction(1);
					}}
				>
					<KeyboardArrowLeft />
				</ToggleButton>
				<ToggleButton
					id="pageNumber1"
					className={buttonSelectedClass(0)}
					value="page1"
					onClick={() => {
						if (forwardDirection) {
							if (activePages[0] - 1 > 0) {
								setActivePages(Array.from(activePages, x => x - 1));
							}
							SetCurrentPageFunction(activePages[0]);
						} else {
							setForwardDirection(true);
							setActivePages([1, 2, 3]);
							SetCurrentPageFunction(1);
						}
					}}
				>
					{forwardDirection ? activePages[0] : 1}
				</ToggleButton>
				<ToggleButton
					id="pageNumber2"
					className={buttonSelectedClass(1)}
					sx={{
						display: activePages[1] >= totalPages ? "none" : "flex"
					}}
					value="page2"
					onClick={() => forwardDirection && SetCurrentPageFunction(activePages[1])}
				>
					{forwardDirection ? activePages[1] : "..."}
				</ToggleButton>
				<ToggleButton
					id="pageNumber3"
					className={buttonSelectedClass(2)}
					value="page3"
					onClick={() => {
						if (forwardDirection) {
							if (activePages[2] + 1 <= totalPages) {
								setActivePages(Array.from(activePages, x => x + 1));
							}
							if (activePages[2] + 1 === totalPages) {
								setForwardDirection(false);
							}
							SetCurrentPageFunction(activePages[2]);
						} else {
							if (activePages[0] <= 2) {
								setForwardDirection(true);
							}
							if (activePages[0] - 1 > 0) {
								setActivePages(Array.from(activePages, x => x - 1));
							}
							SetCurrentPageFunction(activePages[0]);
						}
					}}
				>
					{forwardDirection ? activePages[2] : activePages[0]}
				</ToggleButton>
				<ToggleButton
					id="pageNumber4"
					className={buttonSelectedClass(3)}
					value="page4"
					onClick={() => {
						!forwardDirection && SetCurrentPageFunction(activePages[1]);
					}}
				>
					{forwardDirection ? "..." : activePages[1]}
				</ToggleButton>
				<ToggleButton
					id="pageNumber5"
					className={buttonSelectedClass(4)}
					value="page5"
					onClick={() => {
						if (forwardDirection) {
							setForwardDirection(false);
							setActivePages([totalPages - 2, totalPages - 1, totalPages]);
							SetCurrentPageFunction(totalPages);
						} else {
							if (activePages[2] < totalPages) {
								setActivePages(Array.from(activePages, x => x + 1));
							} else {
								setActivePages([totalPages - 2, totalPages - 1, totalPages]);
							}
							SetCurrentPageFunction(activePages[2]);
						}
					}}
				>
					{forwardDirection ? totalPages : activePages[2]}
				</ToggleButton>
				<ToggleButton
					id="rightButton"
					value="next"
					onClick={() => {
						if (activePages[0] + 3 < totalPages)
							setActivePages(Array.from(activePages, x => x + 3));
						else {
							setForwardDirection(false);
							setActivePages([totalPages - 2, totalPages - 1, totalPages]);
						}
						if (currentPage + 3 <= totalPages) SetCurrentPageFunction(currentPage + 3);
						else SetCurrentPageFunction(totalPages);
					}}
				>
					<KeyboardArrowRight />
				</ToggleButton>
			</ToggleButtonGroup>
		</Box>
	);
};

PaginatedButtonGroup.propTypes = {
	totalPages: PropTypes.number,
	currentPage: PropTypes.number,
	setCurrentPageParent: PropTypes.func,
	currentPageParent: PropTypes.number
};

export default React.memo(PaginatedButtonGroup);
