import { DSPalette } from "@clickbank-ui/seller-design-system";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import React, { forwardRef, useState } from "react";

const { cbNeutral } = DSPalette;

const styles = {
	visibilityButton: {
		width: "70px",
		height: "56px",
		background: "transparent",

		"&:hover": {
			background: "transparent"
		}
	}
};

const PasswordInput = forwardRef((props, ref) => {
	const [showPassword, setShowPassword] = useState(false);

	return (
		<>
			<TextField
				ref={ref}
				fullWidth
				required
				type={showPassword ? "text" : "password"}
				inputProps={{ maxLength: 32 }}
				InputProps={{
					endAdornment: (
						<>
							<Button
								variant="text"
								id="visibilityIconButton"
								onClick={() => setShowPassword(!showPassword)}
								sx={styles.visibilityButton}
								disableRipple
							>
								{showPassword ? (
									<VisibilityOff
										fontSize="small"
										sx={{ color: cbNeutral[700] }}
									/>
								) : (
									<Visibility fontSize="small" sx={{ color: cbNeutral[700] }} />
								)}
							</Button>
						</>
					)
				}}
				{...props}
			/>
		</>
	);
});

export default PasswordInput;
