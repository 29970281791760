import { DesignSystem } from "@clickbank-ui/seller-design-system";
import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Hub } from "aws-amplify";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory } from "react-router-dom";

import { PageView } from "./GoogleAnalytics";
import CustomerSupport from "./components/CustomerSupport";
import Footer from "./components/Footer";
import Kount from "./components/Kount";
import NavBar from "./components/NavBar";
import Main from "./pages/Main";
import { checkCognitoTokenExists } from "./util/authHelpers";

// TODO: Migrate to MUI v5
export default () => {
	const history = useHistory();

	useEffect(() => {
		/* Leaving GA in place until GTM is verified operational */
		ReactGA.initialize("UA-49149418-4");
		PageView();

		/* Initiating GTM */
		const tagManagerArgs = {
			gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || "GTM-000000"
		};

		TagManager.initialize(tagManagerArgs);

		if (window.location.hash) {
			const hash = window.location.hash;
			window.location.href = `${hash.substring(hash.indexOf("/"), hash.length)}`;
		}
	}, []);

	useEffect(() => {
		return history.listen(() => {
			window.scrollTo(0, 0);
			PageView();
		});
	}, [history]);

	const [loggedIn, setLoggedIn] = useState(false);
	useEffect(() => {
		// user may be authenticated anonymously - in that case, we still want to show login/create account
		return Hub.listen("auth", () => setLoggedIn(checkCognitoTokenExists()));
	});

	useTranslation(); // Triggers <Suspense> component; won't translate consistently without it

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={DesignSystem.designSystemV5}>
				<Kount />
				<Switch>
					<Route>
						<NavBar isLoggedIn={loggedIn} />
						<Route exact path="/">
							<CustomerSupport />
						</Route>
						<Main />
						<Footer />
					</Route>
				</Switch>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};
