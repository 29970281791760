import { Box } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import Status from "../Status";

const AlertBanner = ({ color = "primary", text, size, show, style = {} }) => {
	const [isOpen, setIsOpen] = useState(false);
	useEffect(() => {
		setTimeout(() => setIsOpen(show), 0);
	}, [show]);

	return (
		<Box sx={{ width: "100%" }}>
			<Collapse in={isOpen}>
				<Status color={color} text={text} size={size} style={style} />
			</Collapse>
		</Box>
	);
};

AlertBanner.propTypes = {
	color: PropTypes.string,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	size: PropTypes.string,
	show: PropTypes.bool,
	style: PropTypes.object
};

export default AlertBanner;
