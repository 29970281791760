import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { DSPalette } from "@clickbank-ui/seller-design-system";
import { DSBreakpoints } from "@clickbank-ui/seller-design-system";
import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { Help } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Grid, IconButton, Typography, useMediaQuery } from "@mui/material";
import jwt_decode from "jwt-decode";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useForm, useFormState, useWatch } from "react-hook-form";
import { Trans, getI18n } from "react-i18next";
import { useHistory } from "react-router-dom";

import { ApolloClient, REQUEST_VERIFICATION, VERIFICATION } from "../../Api";
import { ReactComponent as CustomerServiceGraphic } from "../../assets/CustomerService.svg";
import { ControlledTextField } from "../../components/ControlledTextField";
import MaintenanceMessage from "../../components/MaintenanceMessage/MaintenanceMessage";
import { disableOrderLookup } from "../../constants/index";
import {
	deleteAnonymousToken,
	getAnonymousToken,
	setAnonymousToken
} from "../../util/anonymousTokenStorage";
import {
	badCc,
	badOrderNo,
	badZip,
	codeBad,
	codeTooManyRequests,
	unknownError
} from "../../util/lookupErrors";
import useMaintenanceMode from "../../util/useMaintenanceMode";
import { ccLast4RegEx, receiptNo, requiredEmail, usZipRegex } from "../../util/validation";
import ContactCardsPanel from "./ContactCardsPanel";
import FAQPanel from "./FAQPanel";
import OrderNumberModal from "./OrderNumberModal";
import VerificationPanel from "./VerificationPanel";

const breakpoints = DSBreakpoints.default;
const { cbNeutral } = DSPalette;

const styles = {
	root: {
		marginTop: "-88px",

		[breakpoints.down("sm")]: {
			marginTop: "-72px"
		},
		"& .MuiTypography-h2": {
			paddingTop: "1em"
		}
	},
	cards: {
		"& .MuiCardActions-root": {
			padding: "0 1rem 1rem"
		},
		"& .MuiCardContent-root": {
			padding: "1rem"
		},
		"& .MuiTypography-body1": {
			fontSize: "1rem"
		},
		"& .MuiTypography-h4": {
			fontSize: "1.25em"
		}
	},
	contactUs: {
		paddingLeft: 0,
		[breakpoints.up("lg")]: {
			paddingLeft: "1rem"
		}
	},
	faq: {
		marginTop: ".5rem"
	},
	findOrder: {
		paddingRight: "1rem",

		[breakpoints.up("sm")]: {
			paddingRight: "3rem"
		}
	},
	graphic: {
		textAlign: "center",

		[breakpoints.down("sm")]: {
			display: "none"
		},
		[breakpoints.up("md")]: {
			marginTop: "2rem",
			paddingLeft: "3rem",
			textAlign: "right"
		},
		"& svg": {
			width: "100%",
			maxWidth: 570,
			height: "auto"
		}
	},
	infoButton: {
		margin: "32px -32px 0 8px",
		padding: 0,
		height: "24px",
		color: cbNeutral[400]
	},
	orderLookupGrid: {
		display: "flex",
		flexDirection: "column",

		"& .MuiFormControl-root": {
			[breakpoints.up("sm")]: {
				maxWidth: 510
			}
		},
		"& .MuiButton-root": {
			width: "100%",
			marginTop: "16px",

			[breakpoints.up("sm")]: {
				maxWidth: "fit-content"
			}
		}
	},
	textCenterInMobileView: {
		[breakpoints.down("sm")]: {
			textAlign: "center"
		}
	}
};

// * This file is a compilation of a number of components in order to
// * clearly outline how everything works. It needs to be broken up
// * in the refactoring endeavor.

// TODO: Break into separate components

const schema = yup.object().shape({
	email: requiredEmail(),
	method: yup
		.object()
		.shape({
			orderNo: receiptNo(),
			ccLast4: yup
				.string()
				.matches(ccLast4RegEx, {
					excludeEmptyString: true,
					message: (
						<Trans i18nKey="OrderLookup.error.ccLastFour">4 digits are required.</Trans>
					)
				})
				.notRequired(),
			zipCode: yup.string().matches(usZipRegex, {
				excludeEmptyString: true,
				message: (
					<Trans i18nKey="OrderLookup.error.zipCodInvalid">Zip code is invalid.</Trans>
				)
			})
		})
		.test(
			"must-select-one",
			() => (
				<Trans i18nKey="OrderLookupFields.mustSelectOne">
					You must enter one of the 3 following fields.
				</Trans>
			),
			({ orderNo, zipCode, ccLast4 }) =>
				orderNo?.length > 0 || zipCode?.length > 0 || ccLast4?.length > 0
		)
});
const OrderLookup = () => {
	const history = useHistory();

	const [showInfoModal, setShowInfoModal] = useState(false);

	const handleInfoModal = () => {
		setShowInfoModal(!showInfoModal);
	};

	const [verificationCode, setVerificationCode] = useState("");
	const [showVerifForm, setShowVerifForm] = useState(false);
	const [verifMsg, setVerifMsg] = useState("");
	const [verifColor, setVerifColor] = useState("success");

	const maintenanceMode = useMaintenanceMode();

	const form = useForm({
		resolver: yupResolver(schema),
		mode: "onTouched",
		reValidateMode: "onChange",
		defaultValues: { email: "", method: { orderNo: "", zipCode: "", ccLast4: "" } }
	});
	const [email, { orderNo, zipCode, ccLast4 }] = useWatch({
		control: form.control,
		name: ["email", "method"]
	});
	const method = orderNo ? "orderNo" : zipCode ? "zipCode" : ccLast4 ? "ccLast4" : null;

	const formState = useFormState({ control: form.control, name: "method" });

	// changing a nested field doesn't seem to invalidate the error state of the parent, hence this jank:
	form.watch((value, { name }) => {
		if (name?.startsWith("method.")) {
			form.trigger("method.root");
		}
	});

	const formattedReceiptNo = () => {
		switch (orderNo.length) {
			case 12:
				return `${orderNo.slice(0, 8)}-${orderNo.slice(8, 12)}`;
			case 16:
				return `${orderNo.slice(0, 8)}-${orderNo.slice(8, 12)}-${orderNo.slice(12, 16)}`;
			default:
				return orderNo;
		}
	};

	const handleVerificationError = error => {
		// Called when verification code submission has failed
		setVerifColor("error");
		setShowVerifForm(false);

		const errorCode = error.message.split(":")[0];
		if (errorCode === "BAD_CODE" || errorCode === "CODE_EXPIRED") {
			setVerifMsg(codeBad);
		} else {
			setVerifMsg(unknownError);
		}
	};

	const handleRequestVerificationError = error => {
		// Called when requesting a verification code has failed
		const errorCode = error.message.split(":")[0];
		switch (errorCode) {
			case "NO_MATCH":
				setVerifColor("error");
				setVerifMsg(
					{
						orderNo: badOrderNo,
						zipCode: badZip,
						ccLast4: badCc
					}[method]
				);
				break;
			case "TOO_MANY_REQUESTS":
				setVerifColor("warning");
				setVerifMsg(codeTooManyRequests);
				break;
			default:
				setVerifColor("error");
				setVerifMsg(unknownError);
				break;
		}
	};

	const resetForm = () => {
		// Excuse the jank until we implement react-hook-form :/
		setShowVerifForm("");
		setVerifMsg("");
		form.reset();
	};

	const [
		verification,
		{ data: verificationData, loading: verificationLoading, called: verificationCalled }
	] = useMutation(VERIFICATION, {
		variables: { email, verificationCode },
		client: ApolloClient,
		onError: handleVerificationError
	});

	const [
		requestVerification,
		{ data: requestVerifData, loading: requestVerifLoading, called: requestVerifCalled }
	] = useMutation(REQUEST_VERIFICATION, {
		variables: {
			email,
			receipt: formattedReceiptNo(orderNo),
			ccLast4,
			zipCode,
			urlPath: "/verifyCode",
			language: getI18n().language
		},
		client: ApolloClient,
		onError: handleRequestVerificationError
	});

	const doCheckVerification = async () => {
		// onSubmit
		const anonymousToken = getAnonymousToken();
		if (anonymousToken) {
			const decodedToken = jwt_decode(anonymousToken);
			if (decodedToken.email === email && decodedToken.exp >= Date.now() / 1000) {
				return orderNo
					? history.push(`/orderDetails?receiptNo=${orderNo}`)
					: history.push("/orderHistory");
			} else {
				deleteAnonymousToken();
			}
		}

		await requestVerification();
	};

	const checkVerification = form.handleSubmit(doCheckVerification);

	const submitOnEnter = e => {
		if (e.key === "Enter") {
			checkVerification();
		}
	};

	const isXs = useMediaQuery("(maxWidth:989px)");

	useEffect(() => {
		// Display success message
		if (requestVerifCalled && !requestVerifLoading && requestVerifData) {
			setShowVerifForm(true);
			setVerificationCode("");
			setVerifColor("success");
			setVerifMsg(
				/* i18next-extract-disable-next-line */
				<Trans i18nKey="OrderLookup.verification.successMsg">
					A verification code has been sent to <strong>{email}</strong>.
					<br />
					This code will expire after <em>one hour</em>.
				</Trans>
			);
			window.scrollTo({
				top: isXs ? 340 : 200,
				left: 0,
				behavior: "smooth"
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requestVerifCalled, requestVerifLoading, requestVerifData, isXs]);

	useEffect(() => {
		// Redirect to order details or history page
		if (verificationCalled && !verificationLoading && verificationData) {
			setAnonymousToken(verificationData.verification);
			return orderNo
				? history.push(`/orderDetails?receiptNo=${formattedReceiptNo()}`)
				: history.push("/orderHistory");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [verificationCalled, verificationLoading, verificationData, orderNo]);

	return (
		<Box>
			<Grid container sx={styles.root}>
				<Grid item md={8} lg={6} sx={styles.findOrder}>
					<Box display="flex" sx={styles.orderLookupGrid}>
						{!maintenanceMode.loading && (
							<MaintenanceMessage
								active={maintenanceMode.active}
								pre={maintenanceMode.pre}
							/>
						)}
						<Typography variant="h2">
							<Trans i18nKey="OrderLookup.title.findMyOrder">Find My Order</Trans>
						</Typography>
						<Typography variant="body2" sx={{ marginTop: "17px" }}>
							<Trans i18nKey="OrderLookup.intro">
								Access your order details, manage a subscription, or create a
								support ticket.
							</Trans>
						</Typography>
						{verifMsg && (
							<Alert
								severity={verifColor}
								sx={{ maxWidth: "470px", marginTop: "16px" }}
							>
								{verifMsg}
							</Alert>
						)}
						{!showVerifForm && (
							<div
								className="mf-form"
								style={
									disableOrderLookup || maintenanceMode.active
										? { pointerEvents: "none", opacity: "0.4" }
										: {}
								}
							>
								<Typography
									variant="body2"
									fontWeight={600}
									sx={{
										padding: "28px 0 8px 8px"
									}}
								>
									<Trans i18nKey="OrderLookup.enterYourEmail">
										1. Enter your purchase email address.
									</Trans>
								</Typography>
								<ControlledTextField
									control={form.control}
									name="email"
									autoFocus
									fullWidth
									id="email"
									label={
										<Trans i18nKey="Common.emailAddress">Email Address</Trans>
									}
									inputProps={{ maxLength: 256 }}
								/>
								<Typography
									variant="body2"
									fontWeight={600}
									sx={{
										padding: "28px 0 8px 8px"
									}}
								>
									<Trans i18nKey="OrderLookup.provideOne">
										2. Provide one of the following:
									</Trans>
								</Typography>
								<ErrorMessage
									errors={formState.errors}
									name="method.root"
									render={({ message }) => (
										<Typography variant="body2" color="error">
											{message}
										</Typography>
									)}
								/>

								<ControlledTextField
									control={form.control}
									name="method.orderNo"
									fullWidth
									id="order-number"
									label={<Trans i18nKey="Common.orderNumber">Order Number</Trans>}
									onKeyDown={submitOnEnter}
									disabled={method && method !== "orderNo"}
									inputProps={{ maxLength: 20 }}
								/>
								<IconButton
									id="infoButton"
									onClick={handleInfoModal}
									sx={styles.infoButton}
								>
									<Help />
								</IconButton>

								{showInfoModal ? (
									<OrderNumberModal
										open={showInfoModal}
										handleCloseModalFn={handleInfoModal}
									/>
								) : null}

								<ControlledTextField
									control={form.control}
									name="method.ccLast4"
									fullWidth
									id="credit-card"
									className="no-mouseflow"
									label={
										<Trans i18nKey="OrderLookupFields.last4Digits">
											Last 4 Digits of Card Used for Purchase
										</Trans>
									}
									onKeyDown={submitOnEnter}
									disabled={method && method !== "ccLast4"}
									inputProps={{ maxLength: 4 }}
								/>
								<ControlledTextField
									control={form.control}
									name="method.zipCode"
									fullWidth
									id="zip-code"
									className="no-mouseflow"
									label={
										<Trans i18nKey="OrderLookupFields.zipCode">
											Zip Code Affiliated with Card
										</Trans>
									}
									onKeyDown={submitOnEnter}
									disabled={method && method !== "zipCode"}
									inputProps={{ maxLength: 10 }}
								/>

								<LoadingButton
									color="primary"
									size="large"
									id="lookUpOrderButton"
									onClick={checkVerification}
									sx={{ display: "block" }}
									className="mf-form-button"
									loading={requestVerifLoading}
								>
									<Trans i18nKey="OrderNumberField.lookUpMyOrder">
										Look Up My Order
									</Trans>
								</LoadingButton>
							</div>
						)}
						{showVerifForm && (
							<div
								style={
									disableOrderLookup || maintenanceMode.active
										? { pointerEvents: "none", opacity: "0.4" }
										: {}
								}
							>
								<VerificationPanel
									email={email}
									verificationCode={verificationCode}
									resetFormFn={resetForm}
									setVerificationCode={setVerificationCode}
									verification={verification}
									verificationLoading={verificationLoading}
									receiptLoading={requestVerifLoading}
									requestVerification={requestVerification}
								/>
							</div>
						)}
					</Box>
				</Grid>
				<Grid item lg={6} sx={styles.contactUs}>
					<Box display="flex" sx={{ ...styles.orderLookupGrid, ...styles.cards }}>
						{" "}
						<ContactCardsPanel />
					</Box>
				</Grid>
				<Grid item sm={9} md={6} sx={styles.faq}>
					<FAQPanel />
				</Grid>
				<Grid item sm={12} md={6} sx={styles.graphic}>
					<CustomerServiceGraphic />
				</Grid>
			</Grid>
		</Box>
	);
};

OrderLookup.propTypes = {
	helpPanelVisibility: PropTypes.bool
};

export default OrderLookup;
