import { ChevronLeft } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";

import { signOut } from "../../Api/Amplify";

export default () => {
	const history = useHistory();
	return (
		<>
			<Typography variant="h2">
				<Trans i18nKey="ContactPanel.notFindingOrderHeader">Not finding your order?</Trans>
			</Typography>
			<Typography>
				<Trans i18nKey="ContactPanel.notFindingOrderPara">
					Make sure you are using the correct email address to search for your order.
				</Trans>
			</Typography>
			<Button
				size="small"
				color="secondary"
				sx={{ marginTop: "24px" }}
				startIcon={<ChevronLeft />}
				id="backtoOrderLookup"
				onClick={async () => {
					await signOut();
					history.push("/");
				}}
			>
				<Trans i18nKey="Common.backToOrderLookup">Back to Order Lookup</Trans>
			</Button>
		</>
	);
};
