import { DSPalette } from "@clickbank-ui/seller-design-system";
import { DSBreakpoints } from "@clickbank-ui/seller-design-system";
import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { Trans } from "react-i18next";

import ViewTicketPanel from "../../components/ViewTicketPanel";
import {
	statusBarColor,
	statusTextColor,
	supportTicketStatus,
	supportTicketTopic,
	supportTicketType
} from "../../constants";
import { AppContext } from "../../context";
import { getTicketStatus } from "../../util/orderHelpers";
import getRandomInt from "../../util/randomID";

const breakpoints = DSBreakpoints.default;
const { palette } = DSPalette;

const styles = {
	rootDiv: {
		margin: "2rem 0 1rem",
		border: "2px solid #F1F1F4",
		borderRadius: "8px"
	},
	ticketContent: {
		display: "flex",
		flexDirection: "column",
		alignContent: "center",
		gap: "1rem",
		padding: "1rem 1.5rem",

		[breakpoints.up("md")]: {
			flexDirection: "row",
			gap: "2rem",
			padding: "1.5rem 2rem"
		},
		"& h5": {
			fontWeight: 600
		}
	},
	titleBoxStyle: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		padding: "1rem 1.5rem",
		background: palette.warning.light,
		borderTopLeftRadius: "6px",
		borderTopRightRadius: "6px",

		[breakpoints.up("md")]: {
			flexDirection: "row",
			alignItems: "center",
			padding: "1rem 2rem"
		}
	}
};

const SupportTicket = ({
	ticket,
	lineItem,
	isSubscriptionCancelable,
	isRefundRequestable,
	displaySecondaryButton = true,
	receipt,
	tickets
}) => {
	const { ticketId, setTicketId } = useContext(AppContext);
	const [ticketAction, setTicketAction] = useState("");
	const handleTicketModalClose = () => setTicketId(null);
	const ticketDate = new Date(ticket?.createTime);
	const ticketStatus = getTicketStatus(ticket, !displaySecondaryButton);

	const buttonStatus = () => {
		switch (ticket?.status) {
			case "CLOSED":
				return <Trans i18nKey="SupportTicket.reopenButton">Reopen Ticket</Trans>;
			default:
				return <Trans i18nKey="SupportTicket.closeTicketButton">Close Ticket</Trans>;
		}
	};

	const getPrimaryTicketAction = () => {
		switch (ticket?.status) {
			case "CLOSED":
				return "reopen";
			default:
				return "reply";
		}
	};

	const getSecondaryTicketAction = () => {
		switch (ticket?.status) {
			case "CLOSED":
				return "reopen";
			default:
				return "close";
		}
	};

	return (
		<Box sx={styles.rootDiv}>
			<Dialog
				maxWidth="sm"
				open={ticketId?.toString() === ticket.id?.toString()}
				onClose={handleTicketModalClose}
				aria-labelledby="view-ticket-modal"
				aria-describedby="view-ticket"
				disablePortal // mouseflow
			>
				<ViewTicketPanel
					handleClose={handleTicketModalClose}
					ticket={ticket}
					relatedTickets={tickets}
					isSubscriptionCancelable={isSubscriptionCancelable}
					isRefundRequestable={isRefundRequestable}
					status={ticketStatus}
					action={ticketAction}
					lineItem={lineItem}
					receipt={receipt}
				/>
			</Dialog>

			<Box sx={{ ...styles.titleBoxStyle, background: statusBarColor[ticketStatus] }}>
				<Typography variant="h3">
					<Trans i18nKey="SupportTicket.titleSupportTicket">Support Ticket:</Trans> #
					{ticket.id}
				</Typography>
				<Typography variant="h5" sx={{ color: statusTextColor[ticketStatus] }}>
					{supportTicketStatus[ticketStatus]}
				</Typography>
			</Box>

			<Box id="parent" sx={styles.ticketContent}>
				<Grid container>
					<Grid item xs={12} md={6} lg={7}>
						<Typography
							sx={{ display: { xs: "block", md: "none" }, marginBottom: "1rem" }}
						>
							<Trans i18nKey="SupportTicket.created">Created</Trans>{" "}
							{ticketDate.toLocaleDateString("en-US")}
						</Typography>
						<Typography variant="h5">
							<Trans i18nKey="SupportTicket.ticketType">Ticket Type</Trans>
						</Typography>
						<Typography>{supportTicketType[ticket?.ticketType]}</Typography>
						<Typography variant="h5" sx={{ marginTop: "1rem" }}>
							<Trans i18nKey="SupportTicket.supportTopic">Support Topic</Trans>
						</Typography>
						<Typography sx={{ marginRight: "2em" }}>
							{supportTicketTopic[ticket.topic]}
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						lg={5}
						sx={{ paddingLeft: { xs: 0, md: "32px", lg: "42px" } }}
					>
						<Typography sx={{ display: { xs: "none", md: "block" } }}>
							<Trans i18nKey="SupportTicket.created">Created</Trans>
							{": "}
							{ticketDate.toLocaleDateString("en-US")}
						</Typography>
						<Button
							fullWidth
							color="secondary"
							sx={{ margin: "1rem 0" }}
							id={"ViewTicketButton" + getRandomInt()}
							onClick={() => {
								setTicketAction(getPrimaryTicketAction());
								setTicketId(ticket.id);
							}}
						>
							<Trans i18nKey="SupportTicket.viewTicketButton">
								View Ticket Details
							</Trans>
						</Button>
						<Button
							fullWidth
							color="secondary"
							id={"CloseTicket" + getRandomInt()}
							sx={{
								display: displaySecondaryButton ? "block " : "none"
							}}
							onClick={() => {
								setTicketAction(getSecondaryTicketAction());
								setTicketId(ticket.id);
							}}
						>
							{buttonStatus()}
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

SupportTicket.propTypes = {
	ticket: PropTypes.object,
	lineItem: PropTypes.object,
	isSubscriptionCancelable: PropTypes.bool,
	isRefundRequestable: PropTypes.bool,
	displaySecondaryButton: PropTypes.bool,
	receipt: PropTypes.string,
	tickets: PropTypes.arrayOf(PropTypes.object)
};

export default SupportTicket;
