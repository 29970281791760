import { useLazyQuery } from "@apollo/client";
import { DSPalette } from "@clickbank-ui/seller-design-system";
import LockIcon from "@mui/icons-material/Lock";
import { Box, InputAdornment, TextField } from "@mui/material";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Trans } from "react-i18next";

import { TOKENEX_IFRAME } from "../../Api";
import { TokenEx } from "../../util/tokenEx";

const { palette } = DSPalette;

const TokenExPanel = ({
	setToken,
	setTokenHash,
	cardType,
	setCardType,
	cardsAccepted,
	setTokenExStatus,
	isRefundAuthFailState
}) => {
	const [lastFour, setLastFour] = useState("");
	const [tokenExConfig, setTokenExConfig] = useState(undefined);
	const [tokenExIframe, setTokenExIframe] = useState(undefined);
	const [isFocused, setIsFocused] = useState(false);
	const [isShrunk, setIsShrunk] = useState(false);
	const [tempTokenExStatus, setTempTokenExStatus] = useState(undefined);

	const isNotValidCard = () => tempTokenExStatus === "required" || tempTokenExStatus === "format";

	const isNotAcceptedCard = () =>
		(tempTokenExStatus === "required" || tempTokenExStatus === "ok") &&
		!cardsAccepted.includes(cardType);
	// don't use the cached result; that can result in stale authentication tokens

	const [tokenExIframeConfig, { data }] = useLazyQuery(TOKENEX_IFRAME, {
		fetchPolicy: "network-only",
		context: {
			noAuth: true
		}
	});

	const insertTokenFrame = config => {
		config.debug = false;
		config.inputType = "text";
		config.enableAutoComplete = true;
		config.styles = {
			base: "box-sizing: border-box; width: 100%; height: 54px; margin-top: 1px; padding: 16px 24px 2px 18px; background-color: rgb(255, 255, 255, 0); border-style: none; font-family: 'proxima-nova', sans-serif; font-size: 18px; letter-spacing: .5px; color: #54527a;",
			focus: "outline: none"
		};

		const target = "tokenEx";
		const theIframe = new TokenEx.Iframe(target, config);

		theIframe.load();

		theIframe.on("load", () => {
			setTokenExIframe(theIframe);
		});
	};

	useEffect(() => {
		tokenExIframeConfig();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		data && setTokenExConfig(data.tokenExIframe);
	}, [data]);

	useEffect(() => {
		tokenExConfig && !tokenExIframe && insertTokenFrame(JSON.parse(tokenExConfig.config));
	}, [tokenExConfig, tokenExIframe]);

	const updateIframe = useCallback(() => {
		if (tokenExIframe) {
			let changed = false;

			tokenExIframe.on("change", () => {
				changed = true;
				setIsShrunk(true);
			});

			tokenExIframe.on("cardTypeChange", data => {
				setCardType(data.possibleCardType);
				changed = true;
			});

			tokenExIframe.on("validate", data => {
				setIsFocused(false);

				const acceptedCard =
					cardsAccepted.includes(data.cardType) || data.cardType === "unknown";

				if (!data.isValid) {
					setCardType("");
					setTokenExStatus(data.validator);
					setTempTokenExStatus(data.validator);
					data.validator === "required" && setIsShrunk(false);
					changed = false;
				} else {
					if (!acceptedCard) {
						setTokenExStatus("format");
						setTempTokenExStatus("format");
					} else {
						if (data.lastFour !== lastFour) changed = true;
						setTokenExStatus("ok");
						setTempTokenExStatus("ok");
						setCardType(data.cardType);
						setLastFour(data.lastFour);
						changed && tokenExIframe.tokenize();
					}
				}
			});

			tokenExIframe.on("focus", () => {
				setIsFocused(true);
				setIsShrunk(true);
			});

			tokenExIframe.on("tokenize", data => {
				setToken(data.token);
				setTokenHash(data.tokenHMAC);
				changed = false;
			});

			tokenExIframe.on("error", () => {
				setTokenExStatus("unknown");
			});
		}
	}, [
		tokenExIframe,
		cardsAccepted,
		setCardType,
		lastFour,
		setLastFour,
		setToken,
		setTokenExStatus,
		setTokenHash
	]);

	useEffect(() => {
		tokenExIframe && updateIframe();
	}, [tokenExIframe, updateIframe]);

	const helpertextFunction = () => {
		if (isRefundAuthFailState && isNotAcceptedCard())
			return (
				<div style={{ color: palette.error.dark }}>
					<Trans i18nKey="helperText.sameCardRequired">
						Card brand must match your initial payment method.
					</Trans>
				</div>
			);
		else if (isNotValidCard())
			return <Trans i18nKey="helperText.cardRequired">Valid Card Required</Trans>;
		else if (isNotAcceptedCard())
			return (
				<div style={{ marginBottom: "20px" }}>
					<Trans i18nKey="helperText.cardNotAccepted">
						{{ cardType }} is not accepted. Please try a different card type.
					</Trans>
				</div>
			);
		return null;
	};

	return (
		<Box
			display="flex"
			width="100%"
			sx={{
				marginBottom: isNotValidCard() || isNotAcceptedCard() ? "15px" : "0px"
			}}
		>
			<TextField
				fullWidth
				sx={{
					minWidth: "100%",
					marginTop: "20px"
				}}
				name="cardToken"
				label={<Trans i18nKey="EditInfoModal.cardNo">Card Number</Trans>}
				type="hidden"
				focused={isFocused}
				error={isNotValidCard() || isNotAcceptedCard()}
				helperText={helpertextFunction()}
				InputLabelProps={{
					shrink: isShrunk
				}}
				InputProps={{
					endAdornment: (
						<InputAdornment
							position="end"
							component="div"
							sx={{
								width: "100%",
								height: 54,
								minHeight: 54,
								marginLeft: 0
							}}
						>
							<span id="tokenEx" style={{ height: "3em", width: "100%" }}></span>
						</InputAdornment>
					)
				}}
			/>
			<LockIcon
				fontSize="small"
				sx={{
					pointerEvents: "none",
					position: "relative",
					right: "40px",
					top: "40px",
					color: palette.text.primary
				}}
			/>
		</Box>
	);
};

TokenExPanel.propTypes = {
	setToken: PropTypes.func,
	setTokenHash: PropTypes.func,
	cardType: PropTypes.string,
	setCardType: PropTypes.func,
	cardsAccepted: PropTypes.arrayOf(PropTypes.string),
	setTokenExStatus: PropTypes.func,
	isRefundAuthFailState: PropTypes.bool
};

export default TokenExPanel;
