import { Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Trans } from "react-i18next";

import { currencyFormatter } from "../../../util/Currencyhelpers.js";

const RecurringBillingInfo = ({ lineItem, currencyCode }) => {
	const subscriptionPrice = currencyFormatter(lineItem.subscriptionPrice, currencyCode);
	const subscriptionFrequency = lineItem.product.priceModel.frequency;
	const recurringCategory = lineItem.product.priceModel.recurringType;
	// note: the translation keys must equal the type, but lowercase
	const recurringType = {
		Weekly: (
			<Trans i18nKey="recurringType.weekly">Weekly Payment of {{ subscriptionPrice }}</Trans>
		),
		"Bi-weekly": (
			<Trans i18nKey="recurringType.bi-weekly">
				Bi-Weekly Payment of {{ subscriptionPrice }}
			</Trans>
		),
		Quarterly: (
			<Trans i18nKey="recurringType.quarterly">
				Quarterly Payment of {{ subscriptionPrice }}
			</Trans>
		),
		Monthly: (
			<Trans i18nKey="recurringType.monthly">
				Monthly Payment of {{ subscriptionPrice }}
			</Trans>
		),
		"Half-yearly": (
			<Trans i18nKey="recurringType.half-yearly">
				Semi-Annual Payment of {{ subscriptionPrice }}
			</Trans>
		),
		Yearly: (
			<Trans i18nKey="recurringType.yearly">Annual Payment of {{ subscriptionPrice }}</Trans>
		),
		Days: (
			<Trans i18nKey="recurringType.days">
				{{ subscriptionPrice }} Payments every {{ subscriptionFrequency }} Days
			</Trans>
		),
		Weeks: (
			<Trans i18nKey="recurringType.weeks">
				{{ subscriptionPrice }} Payments every {{ subscriptionFrequency }} Weeks
			</Trans>
		),
		Months: (
			<Trans i18nKey="recurringType.months">
				{{ subscriptionPrice }} Payments every {{ subscriptionFrequency }} Months
			</Trans>
		)
	};

	const dailyPayment = (
		<Trans i18nKey="recurringType.day">Daily Payment of {{ subscriptionPrice }}</Trans>
	);

	return (
		<Stack spacing={1}>
			<Typography variant="h5" fontWeight={700}>
				{subscriptionFrequency === 1 && recurringCategory === "Days"
					? dailyPayment
					: recurringType[recurringCategory]}
			</Typography>
			{lineItem.quantity && lineItem.quantity > 1 && (
				<Typography variant="h5" fontWeight={400}>
					<Trans i18nKey="ProductDescription.quantity">Quantity:</Trans>{" "}
					{lineItem.quantity}
				</Typography>
			)}
		</Stack>
	);
};

RecurringBillingInfo.propTypes = {
	lineItem: PropTypes.object,
	currencyCode: PropTypes.string
};

export default RecurringBillingInfo;
