import { DSPalette } from "@clickbank-ui/seller-design-system";
import { DSBreakpoints } from "@clickbank-ui/seller-design-system";
import { Language } from "@mui/icons-material";
import { Box, MenuItem, Select } from "@mui/material";
import React, { useEffect } from "react";

import i18n from "../../i18n";

const breakpoints = DSBreakpoints.default;
const { palette } = DSPalette;

const styles = {
	languageSelect: {
		display: "flex",

		[breakpoints.down("xs")]: {
			paddingTop: ".5rem"
		},
		"& .MuiInputBase-input": {
			padding: "0 28px 0 8px !important",

			"&.MuiSelect-select:hover": {
				backgroundColor: "Transparent"
			}
		},
		"& .MuiInputBase-root": {
			height: "auto"
		}
	},
	startAdornment: {
		marginRight: "4px",
		height: "18px",
		width: "18px",
		color: palette.primary.main,
		pointerEvents: "none",
		zIndex: 5
	}
};

const languages = {
	en: "English",
	de: "Deutsch",
	es: "Español",
	fr: "Français",
	it: "Italiano",
	pt: "Português"
};

export default () => {
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const locale = urlParams.get("lang");
		if (locale) {
			// always remove lang param
			// this avoids conflicts if the user tries to manually change the language
			const url = new URL(window.location.href);
			const lang = url.searchParams.get("lang").substring(0, 2);
			if (lang) {
				url.searchParams.delete("lang");
				window.history.pushState({ path: url.href }, "", url.href);
				i18n.changeLanguage(lang.toLowerCase());
			}
			urlParams.delete("lang");
		}
	}, []);
	const handleChange = async event => {
		if (languages[event.target.value]) {
			await i18n.changeLanguage(event.target.value);
			window.location.reload();
			window.scrollTo(0, 0); // window was scrolling to bottom :/
		}
	};

	return (
		<Box sx={styles.languageSelect}>
			<Select
				disableUnderline
				variant="standard"
				defaultValue={i18n.language}
				id="languagePicker"
				onChange={handleChange}
				startAdornment={<Language sx={styles.startAdornment} />}
			>
				{Object.keys(languages).map(lang => (
					<MenuItem key={lang} value={lang}>
						{languages[lang]}
					</MenuItem>
				))}
			</Select>
		</Box>
	);
};
