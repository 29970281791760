import * as yup from "yup";
import { DSBreakpoints, DSPalette } from "@clickbank-ui/seller-design-system";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChevronLeft } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Grid, Link, Typography } from "@mui/material";
import React, { createRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";

import { requestPasswordReset, resendSignUp, signIn } from "../../Api/Amplify";
import { ControlledTextField } from "../../components/ControlledTextField";
import MaintenanceMessage from "../../components/MaintenanceMessage/MaintenanceMessage";
import PasswordInput from "../../components/PasswordInput";
import Recaptcha from "../../components/Recaptcha";
import assetPath from "../../config/config.js";
import { disableLogin } from "../../constants/index";
import { getCognitoErrorCode } from "../../util/cognitoErrors";
import useMaintenanceMode from "../../util/useMaintenanceMode";
import { requiredEmail } from "../../util/validation";

const breakpoints = DSBreakpoints.default;
const { palette } = DSPalette;

const styles = {
	overLapGrid: {
		minHeight: 270,
		marginLeft: " -70px",
		marginRight: "-60px",

		"& img": {
			width: "100%",
			height: "auto"
		}
	},
	textFieldBox: {
		width: "100%",

		[breakpoints.up("sm")]: {
			width: 480
		},
		"& .MuiButton-containedPrimary": {
			marginTop: "40px"
		},
		"& .backButton": {
			margin: "16px 0 64px"
		}
	},
	textBox: {
		zIndex: 4,
		width: "100%",

		[breakpoints.up("sm")]: {
			maxWidth: 580
		},
		"& .MuiAlert-root": {
			marginTop: "2rem"
		}
	},
	recaptcha: {
		height: 65,
		marginTop: "40px",

		[breakpoints.up("md")]: {
			marginTop: "40px"
		}
	},
	forgotLink: {
		position: "absolute",
		top: 82,
		right: 3,
		fontSize: "14px"
	}
};

const schema = yup.object().shape({
	email: requiredEmail(),
	password: yup
		.string()
		.required(<Trans i18nKey="LoginForm.passwordRequired">Password is required.</Trans>)
});

export default () => {
	const form = useForm({
		resolver: yupResolver(schema),
		mode: "onTouched",
		defaultValues: { email: "", password: "" }
	});

	const history = useHistory();
	const recaptchaRef = createRef();
	const logo60 = assetPath.concat("images/Frame_60_1.png");

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(undefined);

	const [userNotConfirmed, setUserNotConfirmed] = useState(false);
	const [accountCreationSuccess, setAccountCreationSuccess] = useState(false);
	const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
	const [passwordResetFailed, setPasswordResetFailed] = useState(false);
	const [mustResetPassword, setMustResetPassword] = useState(false);
	const [accountCreationResent, setAccountCreationResent] = useState(false);
	const [hasClickedResend, setHasClickedResend] = useState(false);

	const maintenanceMode = useMaintenanceMode();

	function setEmail(email) {
		form.setValue("email", email);
	}

	function getEmail() {
		return form.getValues("email");
	}

	/**
	 * Handles confirmSignUp results
	 * @param {"signUpSuccess"|"signUpFailed"|"signUpResent"|"passwordResetSuccess"|"passwordResetFailed"} status
	 */
	const handleSignUpStatus = status => {
		switch (status) {
			case "signUpSuccess":
				setAccountCreationSuccess(true);
				break;
			case "signUpFailed":
				setEmail("");
				setError(
					<Trans i18nKey="Cognito.UnableToConfirmAccount">
						An unknown error occurred and we were not able to confirm your account.
						Please contact customer support if this issue persists.
					</Trans>
				);
				break;
			case "signUpResent":
				setAccountCreationResent(true);
				break;
			case "passwordResetSuccess":
				setPasswordResetSuccess(true);
				setTimeout(() => setPasswordResetSuccess(false), 8000);
				break;
			case "passwordResetFailed":
				setPasswordResetFailed(true);
				setTimeout(() => setPasswordResetFailed(false), 8000);
				break;
			default:
				//do nothing
				break;
		}
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const email = urlParams.get("email");
		const status = urlParams.get("status");
		if (email) setEmail("", email);
		if (status && email) {
			handleSignUpStatus(status, email);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = async ({ email, password }) => {
		let token;

		//Recaptcha Logic - disable-recaptcha is used by automated tests in tst to skip recaptcha
		const disableRecaptcha = localStorage.getItem("disable-recaptcha");
		if (disableRecaptcha !== "true") {
			recaptchaRef.current.reset();
			try {
				token = await recaptchaRef.current.executeAsync();
			} catch (error) {
				if (recaptchaRef.current) recaptchaRef.current.reset();
				setError(
					<Trans i18nKey="CreateAccountPage.recaptchaFailed">
						An authentication error occurred, please refresh the page and try again.
					</Trans>
				);
				setTimeout(() => setError(undefined), 5000);
				return;
			}
		}

		setLoading(true);

		//Login
		try {
			await signIn(email, password, token);
			setLoading(false);
			history.push("/orderHistory");
		} catch ({ code, message }) {
			if (code === "UserNotConfirmedException") {
				setUserNotConfirmed(true);
			} else if (
				code === "PasswordResetRequiredException" ||
				message.includes("PasswordResetRequiredException")
			) {
				await requestPasswordReset(email);
				setMustResetPassword(true);
				setTimeout(() => setMustResetPassword(false), 8000);
			} else {
				setError(getCognitoErrorCode(code));
				setTimeout(() => setError(undefined), 5000);
			}
			setLoading(false);
		}
	};

	const submit = form.handleSubmit(handleSubmit, fieldErrors =>
		console.warn("Validation failed", fieldErrors)
	);
	const handleConfirmationClick = async () => {
		setUserNotConfirmed(false);
		try {
			await resendSignUp(getEmail());
			setTimeout(() => setHasClickedResend(true), 500);
		} catch ({ code }) {
			setError(getCognitoErrorCode(code));
			setTimeout(() => setError(undefined), 5000);
		}
	};

	return (
		<Grid container>
			<Grid item sx={styles.textBox} sm>
				<Typography variant="h2">
					{accountCreationSuccess ? (
						<Trans i18nKey="LoginForm.thankYou">Thank You!</Trans>
					) : (
						<Trans i18nKey="LoginForm.customerAccountLogin">
							Customer Account Login
						</Trans>
					)}
				</Typography>

				{!maintenanceMode.loading && (
					<MaintenanceMessage active={maintenanceMode.active} pre={maintenanceMode.pre} />
				)}

				<Typography variant="body1" sx={{ marginTop: "20px" }}>
					{accountCreationSuccess ? (
						<Trans i18nKey="LoginForm.thankYouForAccountCreation">
							Your email has been verified and your account is now active. Please
							login below to continue to your account.
						</Trans>
					) : (
						<Trans i18nKey="LoginForm.mainParagraph">
							Enter your email address and account password to access your ClickBank
							Customer Account.
						</Trans>
					)}
				</Typography>

				{(passwordResetSuccess || passwordResetFailed) && (
					<Alert severity={passwordResetFailed ? "error" : "success"}>
						{passwordResetFailed ? (
							<Trans i18nKey="LoginForm.passWordResetFailed">
								An unknown error occurred and we were not able to change your
								password. Please contact customer support if this issue persists.
							</Trans>
						) : (
							<Trans i18nKey="LoginForm.passWordResetSuccess">
								Your password has been successfully updated.
							</Trans>
						)}
					</Alert>
				)}

				{accountCreationResent && (
					<Alert severity="info">
						<Trans i18nKey="LoginForm.codeExpiryCreationResent">
							This email confirmation link has expired.
							<br /> A new email confirmation has been sent, please check your email
							for next steps.
						</Trans>
					</Alert>
				)}

				{mustResetPassword && (
					<Alert severity="info">
						<Trans i18nKey="LoginForm.mustResetPassword">
							You must reset your password.
							<br /> A link to reset your password has been sent to your email, please
							check your email for next steps.
						</Trans>
					</Alert>
				)}

				{(userNotConfirmed || hasClickedResend) && (
					<Alert severity={hasClickedResend ? "success" : "info"}>
						{hasClickedResend ? (
							<Trans i18nKey="LoginForm.confirmationResent">
								Your confirmation email has been resent, please check your email for
								next steps.
							</Trans>
						) : (
							<>
								<Trans i18nKey="LoginForm.notConfirmedYourAccount">
									You have not confirmed your account.
								</Trans>
								<br />
								<Link onClick={() => handleConfirmationClick()}>
									<Trans i18nKey="LoginForm.clickToResendConfirmation">
										Click here to resend your confirmation.
									</Trans>
								</Link>
							</>
						)}
					</Alert>
				)}

				{!!error && <Alert severity="error">{error}</Alert>}

				<div
					className="ms-form"
					style={
						disableLogin || maintenanceMode.active
							? { pointerEvents: "none", opacity: "0.4" }
							: {}
					}
				>
					<Box sx={styles.textFieldBox}>
						<ControlledTextField
							control={form.control}
							name="email"
							id="email"
							label={<Trans i18nKey="Common.emailAddress">Email Address</Trans>}
							fullWidth
							sx={{ marginTop: "20px" }}
							disabled={loading}
						/>
						<Box sx={{ position: "relative" }}>
							<ControlledTextField
								control={form.control}
								name="password"
								component={PasswordInput}
								id="password"
								label={<Trans i18nKey="Common.password">Password</Trans>}
								disabled={loading}
								onKeyPress={e => {
									if (e.key === "Enter" && (!loading || !mustResetPassword)) {
										submit();
									}
								}}
							/>
							<Typography color="primary" sx={styles.forgotLink}>
								<Link
									component="button"
									id="forgotPassLink"
									underline="none"
									onClick={() =>
										history.push(
											`/forgotPassword${
												getEmail()
													? "?email=" + encodeURIComponent(getEmail())
													: ""
											}`
										)
									}
									sx={{
										marginRight: "3px",
										"&.MuiTypography-root": { color: palette.primary.main }
									}}
								>
									<Trans i18nKey="LoginForm.forgotPassword">
										Forgot Password
									</Trans>
								</Link>
							</Typography>
						</Box>
						<Grid container direction="column">
							<LoadingButton
								fullWidth
								id="loginButton"
								color="primary"
								disabled={loading || mustResetPassword}
								onClick={submit}
								loading={loading}
							>
								<Trans i18nKey="LoginForm.login">Login</Trans>
							</LoadingButton>
							<Button
								fullWidth
								color="secondary"
								className="backButton"
								startIcon={<ChevronLeft />}
								id="backButton"
								onClick={() => {
									history.push("/");
								}}
							>
								<Trans i18nKey="Common.backToOrderLookup">
									Back to Order Lookup
								</Trans>
							</Button>
						</Grid>
					</Box>
				</div>
				<Recaptcha sx={styles.recaptcha} forwardRef={recaptchaRef} />
			</Grid>

			<Grid item sx={{ display: { xs: "none", md: "flex" } }} sm>
				<Box sx={styles.overLapGrid}>
					<img src={logo60} alt="Customer Care"></img>
				</Box>
			</Grid>
		</Grid>
	);
};
