import { DSPalette } from "@clickbank-ui/seller-design-system";
import { Box, Grid, TextField, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Trans } from "react-i18next";

const { cbBrand, palette } = DSPalette;

const styles = {
	categoryChild: {
		marginTop: "20px"
	},
	formControlText: {
		color: cbBrand.darkGrey,
		fontWeight: "400"
	},
	formControlTextSelected: {
		color: cbBrand.darkGrey,
		fontWeight: "700"
	},
	helperText: {
		color: palette.text.secondary,
		fontSize: "14px"
	}
};

const ViewTicketAction = ({
	ticketAction,
	handleChangeTicketAction,
	ticketType,
	isRefundRequestable,
	isSubscriptionCancelable,
	comment,
	handleChangeComment,
	status,
	editLoading,
	escalateLoading
}) => {
	const [commentError, setCommentError] = useState(false);
	const isValidComment = new RegExp(/[a-zA-Z0-9-./'#,":{}()!@%^&*]+/).test(comment);
	const openTicketActions = () => (
		<>
			<Grid item>
				<FormControlLabel
					value="reply"
					control={<Radio />}
					label={<Trans i18nKey="ViewTicketPanel.reply">Reply</Trans>}
					sx={
						ticketAction === "reply"
							? { label: styles.formControlTextSelected }
							: { label: styles.formControlText }
					}
				/>
			</Grid>
			<Grid item>
				<FormControlLabel
					value="close"
					control={<Radio />}
					label={<Trans i18nKey="ViewTicketPanel.closeTicket">Close Ticket</Trans>}
					sx={
						ticketAction === "close"
							? { label: styles.formControlTextSelected }
							: { label: styles.formControlText }
					}
				/>
			</Grid>
			{ticketType !== "TECH_SUPPORT" && (
				<Grid item>
					<FormControlLabel
						value="techSupport"
						control={<Radio />}
						label={
							<Trans i18nKey="ViewTicketPanel.changeTechSupportRequest">
								Change to Technical Support
							</Trans>
						}
						sx={
							ticketAction === "techSupport"
								? { label: styles.formControlTextSelected }
								: { label: styles.formControlText }
						}
					/>
				</Grid>
			)}
			{ticketType !== "REFUND" && isRefundRequestable && (
				<Grid item>
					<FormControlLabel
						value="requestRefund"
						control={<Radio />}
						label={
							<Trans i18nKey="ViewTicketPanel.changeRequestRefund">
								Change to Refund Request
							</Trans>
						}
						sx={
							ticketAction === "requestRefund"
								? { label: styles.formControlTextSelected }
								: { label: styles.formControlText }
						}
					/>
				</Grid>
			)}
			{ticketType !== "CANCEL" && isSubscriptionCancelable && (
				<Grid item>
					<FormControlLabel
						value="cancelSubscription"
						control={<Radio />}
						label={
							<Trans i18nKey="ViewTicketPanel.changeCancelSubscription">
								Change to Cancellation Request
							</Trans>
						}
						sx={
							ticketAction === "cancelSubscription"
								? { label: styles.formControlTextSelected }
								: { label: styles.formControlText }
						}
					/>
				</Grid>
			)}
		</>
	);

	const closedTicketActions = () => (
		<>
			<Grid item>
				<FormControlLabel
					value="reopen"
					control={<Radio />}
					label={<Trans i18nKey="ViewTicketPanel.reopen">Reopen Ticket</Trans>}
					sx={
						ticketAction === "reopen"
							? { label: styles.formControlTextSelected }
							: { label: styles.formControlText }
					}
				/>
			</Grid>
		</>
	);

	return (
		<>
			<Box>
				<RadioGroup
					aria-label="action-selection"
					name="actions"
					value={ticketAction}
					sx={styles.categoryChild}
					onChange={handleChangeTicketAction}
					disabled={editLoading || escalateLoading}
				>
					<Grid container spacing={1}>
						{status !== "CLOSED" ? openTicketActions() : closedTicketActions()}
					</Grid>
				</RadioGroup>
			</Box>
			<Box>
				<TextField
					label={<Trans i18nKey="ViewTicketPanel.comment">Comment</Trans>}
					id="outlined-textareaticket"
					required
					fullWidth
					multiline
					minRows={4}
					sx={styles.categoryChild}
					value={comment}
					onChange={handleChangeComment}
					onBlur={() => {
						setCommentError(!isValidComment);
					}}
					error={commentError && !isValidComment}
					disabled={editLoading || escalateLoading}
					helperText={
						commentError && !isValidComment ? (
							<Typography sx={{ ...styles.helperText, color: palette.error.main }}>
								<Trans i18nKey="ViewTicketPanel.commentRequiredError">
									Comment is required
								</Trans>
							</Typography>
						) : (
							<Typography sx={styles.helperText}>
								{"("}
								<Trans i18nKey="Common.characterLimit">Character limit</Trans>:{" "}
								{comment.length}/500{")"}
							</Typography>
						)
					}
				/>
			</Box>
		</>
	);
};

ViewTicketAction.propTypes = {
	ticketAction: PropTypes.oneOf([
		"reply",
		"close",
		"techSupport",
		"requestRefund",
		"cancelSubscription",
		"reopen",
		"escalate"
	]),
	handleChangeTicketAction: PropTypes.func,
	ticketType: PropTypes.oneOf(["REFUND", "TECH_SUPPORT", "CANCEL"]),
	isRefundRequestable: PropTypes.bool,
	isSubscriptionCancelable: PropTypes.bool,
	comment: PropTypes.string,
	handleChangeComment: PropTypes.func,
	status: PropTypes.string,
	editLoading: PropTypes.bool,
	escalateLoading: PropTypes.bool
};

export default ViewTicketAction;
